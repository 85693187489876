import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../ui/table";
import { Checkbox } from "../../../../ui/checkbox";

const ListofStudents = ({ students, attendance, setAttendance }) => {
  // Improved handler for checkbox changes with better logging
  const handleAttendanceCheckbox = (studentId) => {
    
    const newValue = !attendance[studentId];
    
    const newAttendance = {
      ...attendance,
      [studentId]: newValue,
    };
    setAttendance(newAttendance);
  };

  return (
    <div className="student-attendance">
      <label className="mb-2 font-semibold">Mark Attendance:</label>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">ID</TableHead>
            <TableHead>Nombre</TableHead>
            <TableHead>Asistencia</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {students.map((student) => (
            <TableRow key={student.id}>
              <TableCell>{student.id}</TableCell>
              <TableCell className="font-medium">{student.fullName}</TableCell>
              <TableCell>
                <div className="flex items-center justify-center">
                  <Checkbox
                    id={`attendance-${student.id}`}
                    checked={!!attendance[student.id]}
                    onCheckedChange={() => handleAttendanceCheckbox(student.id)}
                    className="cursor-pointer"
                  />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      
      <div className="mt-4 text-sm text-gray-500">
        Total asistentes: {Object.values(attendance).filter(Boolean).length} de {students.length}
      </div>
    </div>
  );
};

export default ListofStudents;
