import React from 'react';
import NavMobile from '../Nav/NavMobile';
import NavDesktop from '../Nav/NavWeb';

const TeacherGuide = () => {
  return (
    <div className="min-h-screen flex">
      {/* Hamburger Sidebar for small screens */}
      <NavMobile />
      {/* Sidebar for large screens */}
      <NavDesktop />

      {/* Main Content */}
      <div className="flex-1 p-6 bg-gray-100">
        <header className="mb-6">
          <h1 className="text-2xl font-bold">Teacher Guide</h1>
          <p className="text-sm text-gray-600">How to record your hours in the platform</p>
        </header>

        <section>
          <h2 className="text-xl font-bold mb-4">Steps to Record Hours</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div className="flex flex-col items-center">
              <div className="h-12 w-12 rounded-full bg-green-500 text-white flex items-center justify-center mb-2">1</div>
              <p className="text-center">Access your account using your username and password.</p>
            </div>
            <div className="flex flex-col items-center">
              <div className="h-12 w-12 rounded-full bg-green-500 text-white flex items-center justify-center mb-2">2</div>
              <p className="text-center">Go to the hours registration section and complete the required information.</p>
            </div>
            <div className="flex flex-col items-center">
              <div className="h-12 w-12 rounded-full bg-green-500 text-white flex items-center justify-center mb-2">3</div>
              <p className="text-center">Make sure to include details such as topic, duration, date, attendees, and whether there were cancellations.</p>
            </div>
          </div>
        </section>

        <section className="bg-white p-6 rounded-lg shadow-lg mt-6">
          <h3 className="text-lg font-bold mb-4">Important Details</h3>
          <ul className="list-disc pl-6">
            <li className="mb-2">If you make a mistake when recording your hours, contact the administrator to correct it.</li>
            <li className="mb-2">For access issues, please contact the administrator.</li>
            <li>Records rejected by the administrator are automatically deleted.</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default TeacherGuide;
