import { toast } from "sonner";
export async function putBankData(teacherData) {
    const url = `${process.env.REACT_APP_API_URL}/admin/profesorbanco/actualizar`;
    const token = sessionStorage.getItem('token');

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(teacherData)
        });

        const contentType = response.headers.get('content-type');
        const responseText = await response.text();

        if (!response.ok) {
            toast.error("Error al editar información bancaria del profesor");    
            console.error('Server responded with:', responseText);
            throw new Error('Failed to update bank data');
        }

        if (contentType && contentType.includes('application/json')) {
            return JSON.parse(responseText);
        } else {
            toast.success("Información bancaria actualizada con éxito");
            return { message: responseText };
        }
    } catch (error) {
        toast.error("Error al editar información bancaria del profesor");
        console.error('Error updating bank data:', error);
        throw error;
    }
}

