import { toast } from 'sonner';

export async function postORputClass(individualClass, id) {
    const token = sessionStorage.getItem('token');
    
    try {
        // Input validation
        if (!individualClass) {
            toast.error("Datos de clase inválidos");
            throw new Error('Missing class data');
        }

        if (!token) {
            toast.error("Sesión inválida. Inicia sesión nuevamente");
            throw new Error('Missing authentication token');
        }

        let response, url, method, data;
        
        // Determinar si es actualización (PUT) o creación (POST)
        if (id) {
            // Actualización (PUT)
            url = `${process.env.REACT_APP_API_URL}/admin/clase/actualizar/${id}`;
            method = 'PUT';
            data = individualClass; // Puede contener adminClassDTO o adminClassDTO+classCancellationDTO
        } else {
            // Creación (POST)
            url = `${process.env.REACT_APP_API_URL}/admin/clase/crear`;
            method = 'POST';
            data = individualClass.adminClassDTO || individualClass;
        }
        
        // Realizar la solicitud
        response = await fetch(url, {
            method,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });

        // Obtener encabezados y texto de la respuesta
        const contentType = response.headers.get('content-type');
        const responseText = await response.text();
        console.log("API response:", response.status, responseText);
        
        if (!response.ok) {
            // Parse response text as JSON if possible
            let parsedError = null;
            let errorMessage = "Error al procesar la solicitud";
            
            if (contentType && contentType.includes('application/json') && responseText) {
                try {
                    parsedError = JSON.parse(responseText);
                    if (parsedError.message) {
                        errorMessage = parsedError.message;
                    }
                } catch (e) {
                    // Keep default error message if parsing fails
                }
            }
            
            // Handle specific error cases
            if (responseText === 'Student does not have enough hours remaining' || 
                responseText.includes('hours remaining') || 
                errorMessage.includes('hours remaining')) {
                toast.error("El estudiante no tiene suficientes horas restantes");
                throw new Error('El estudiante no tiene suficientes horas restantes');
            }
            
            // Handle different error types based on status codes
            switch (response.status) {
                case 400:
                    toast.error("Datos inválidos. Comprueba la información de la clase.");
                    break;
                case 401:
                    toast.error("No autorizado. Inicia sesión nuevamente.");
                    sessionStorage.removeItem('token');
                    break;
                case 403:
                    toast.error("No tienes permisos para gestionar esta clase.");
                    break;
                case 404:
                    toast.error("Clase no encontrada. Verifica el identificador.");
                    break;
                case 409:
                    toast.error("Conflicto al programar la clase. Verifica horarios y disponibilidad.");
                    break;
                case 422:
                    toast.error("Error de validación. Comprueba los datos de la clase.");
                    break;
                case 500:
                    toast.error("Error del servidor. Intenta más tarde.");
                    break;
                default:
                    toast.error(errorMessage || "Error al procesar la solicitud de clase");
            }
            
            throw new Error(`Failed to process class: ${response.status} ${response.statusText} - ${errorMessage}`);
        }
        let result;
        if (contentType && contentType.includes('application/json') && responseText) {
            try {
                result = JSON.parse(responseText);
            } catch (e) {
                console.log("Response is not valid JSON, returning as text");
                result = { message: responseText || (id ? "Clase actualizada con éxito" : "Clase creada con éxito") };
            }
        } else {
            result = { message: responseText || (id ? "Clase actualizada con éxito" : "Clase creada con éxito") };
        }
        
        // Mostrar mensaje de éxito
        toast.success(id ? "Clase actualizada con éxito" : "Clase creada con éxito");
        return result;
        
    } catch (error) {
        // Only show generic toast for errors that haven't already been handled
        if (!error.message.includes('Failed to process class') && 
            !error.message.includes('El estudiante no tiene suficientes horas restantes')) {
            
            if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
                toast.error("Error de conexión. Verifica tu conexión a internet.");
            } else {
                toast.error(`Error: ${error.message || 'Ha ocurrido un problema al procesar la solicitud'}`);
            }
        }
        
        console.error('Error processing class request:', error);
        throw error;
    }
}