import { toast } from "sonner";
export async function infoDashboardTeacher(year, month) {
    const token = sessionStorage.getItem("token");
    
    if (!token) {
        toast.error("Sesión inválida. Por favor inicie sesión nuevamente.");
        throw new Error("Sesión inválida. Por favor inicie sesión nuevamente.");
    }
    
    try {
        const url = `${process.env.REACT_APP_API_URL}/profesor/informacion/dashboard?year=${year}&month=${month}`; 
        
        const resp = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        

        const data = await resp.json();
        
        // Check if we have meaningful data
        const isEmpty = !data || 
            (data.hoursHeld === 0 && 
            data.totalVirtualValue === 0 && 
            data.totalInPersonValue === 0 &&
            (!data.monthlyClassStats || data.monthlyClassStats.length === 0));
        
        if (isEmpty) {
            toast.warning("No hay datos disponibles para mostrar. Esto puede deberse a que no se le han asignado clases aún.");
            throw new Error("No hay datos disponibles para mostrar");
        }

        const classList = {
            hoursCanceledParticipantOnTime: data.hoursCanceledParticipantOnTime || 0,
            hoursCanceledTeacherOnTime: data.hoursCanceledTeacherOnTime || 0,
            classesHeldInPerson: data.classesHeldInPerson || 0,
            classesHeldVirtual: data.classesHeldVirtual || 0,
            hoursHeld: data.hoursHeld || 0,
            hoursHeldVirtual: data.hoursHeldVirtual || 0,
            hoursHeldInPerson: data.hoursHeldInPerson || 0,
            hoursPlanned: data.hoursPlanned || 0,
            monthlyClassStats: data.monthlyClassStats || [],
            totalVirtualValue: data.totalVirtualValue || 0,
            totalInPersonValue: data.totalInPersonValue || 0,
            hoursCanceledParticipantLateVirtual: data.hoursCanceledParticipantLateVirtual || 0,
            hoursCanceledParticipantLateInPerson: data.hoursCanceledParticipantLateInPerson || 0,
            hoursCanceledTeacherLate: data.hoursCanceledTeacherLate || 0
        };
        return classList;
        
    } catch (error) {
        // Don't show toast if we've already shown one for this error
        if (!error.message.includes("No hay datos disponibles") && 
            !error.message.includes("No tiene autorización") &&
            !error.message.includes("No se encontró la información") &&
            !error.message.includes("Error del servidor")) {
            
            if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
                toast.error("Error de conexión. Verifique su conexión a internet.");
            } else
            {
              toast.error("No hay datos disponibles para mostrar. Esto puede deberse a que no tiene clases asignadas, no ha subido su información bancaria o no tiene valores por hora configurados.");
            }
        }
        
        throw error;
    }
}
