//Solo recibe estas fechas
//startDate: 2021-06-01T00:00:00.000Z
//endDate: 2021-06-30T23:59:59.999Z 
// en el formato de fecha y hora de JavaScript
import { toast } from "sonner";
export async function getClassesByTeacherAndDateTimeBetweenDays(startDate, endDate) {
    const token = sessionStorage.getItem("token");
    
    if (!token) {
        toast.error("Sesión inválida. Por favor inicie sesión nuevamente.");
        throw new Error("Sesión inválida. Por favor inicie sesión nuevamente.");
    }
    
    try {
        // Build URL with query parameters
        let url = `${process.env.REACT_APP_API_URL}/profesor/clase?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
        
        // Make the API request
        const response = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        
        // Check if response is OK
        if (!response.ok) {
            if (response.status === 401 || response.status === 403) {
                toast.error("No tiene autorización para acceder a estas clases. Por favor inicie sesión nuevamente.");
                throw new Error("No tiene autorización para acceder a estas clases");
            } else if (response.status === 404) {
                toast.error("No se encontraron clases para el período seleccionado.");
                throw new Error("No se encontraron clases para el período seleccionado");
            } else {
                toast.error(`Error del servidor: ${response.status}`);
                throw new Error(`Error del servidor: ${response.status}`);
            }
        }
        
        // Parse the data
        const data = await response.json();
        
        // Check if data is empty
        if (!data || data.length === 0) {
            toast.warning("No se encontraron clases para el período seleccionado. Esto puede deberse a que no se le han asignado clases aún.");
            throw new Error("No se encontraron clases para el período seleccionado");
        }
        
        return data;
        
    } catch (error) {
        // Only log to console in development environment
        if (process.env.NODE_ENV === 'development') {
            console.error("Error fetching classes:", error.message);
        }
        
        // Don't show toast if we've already shown one for this error
        if (!error.message.includes("No se encontraron clases") && 
            !error.message.includes("No tiene autorización") &&
            !error.message.includes("Error del servidor")) {
            
            if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
                toast.error("Error de conexión. Verifique su conexión a internet.");
            } else {
                toast.error("Error al obtener información de clases");
            }
        }
        
        throw error;
    }
}
