import React, { useEffect, useState } from "react";
import { Button } from "../../ui/button";
import Loader from "../../Loader/Loader";
import { getBankDataTeacherbyID } from "../../../provider/adm/profesores/BankData/getBankData";
import { postBankData } from "../../../provider/adm/profesores/BankData/postBankData";
import { putBankData } from "../../../provider/adm/profesores/BankData/PutBankData";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Dialog, DialogContent, DialogTrigger } from "../../ui/dialog";
import CrearEditarTimeValueProfesores from "./CrearEditValorHoraProfesores";
const CrearEditarProfesorBankData = ({ personal_info_teacher }) => {
  const [bankdata, setBankData] = useState(null);
  const [contractCity, setContractCity] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [selectedBankId, setSelectedBankId] = useState("");
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([]);
  const [timeValue, setTimeValue] = useState(null);

  // Fetch banks from API
  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/profesorbanco/bancos`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });
        const data = await response.json();
        setBanks(data);
      } catch (error) {
        console.error("Error fetching banks:", error);
      }
    };
    fetchBanks();
  }, []);

  useEffect(() => {
    const fetchBankData = async () => {
      try {
        const data = await getBankDataTeacherbyID(personal_info_teacher.id);
        setBankData(data);
        
        // Set timeValue separately
        if (data && data.timeValue) {
          setTimeValue(data.timeValue);
        }
      } catch (error) {
        console.error("Error fetching bank data:", error);
      }
    };
    fetchBankData();
  }, [personal_info_teacher.id]);

  useEffect(() => {
    if (bankdata) {
      setContractCity(bankdata.contractCity || "");
      setBankAccountNumber(bankdata.bankAccountNumber || "");
      setSelectedBankId(bankdata.bank?.id || "");
    }
  }, [bankdata]);

  const validateFields = () => {
    if (
      !contractCity ||
      !bankAccountNumber ||
      !selectedBankId
    ) {
      alert("Please fill in all required fields.");
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    if (!validateFields()) return;

    if (window.confirm("Are you sure you want to save the changes?")) {
      setLoading(true);

      const formattedData = {
        contractCity,
        timeValueId: 1, // Always 1 as specified
        bankAccountNumber,
        bankId: parseInt(selectedBankId),
        teacherId: personal_info_teacher.id,
      };
      
      try {
        if (bankdata && bankdata.id) {
          await putBankData(formattedData);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          await postBankData(formattedData);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } catch (error) {
        console.error("Error saving bank data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Ciudad de Contrato
          </label>
          <input
            type="text"
            value={contractCity}
            onChange={(e) => setContractCity(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Enter contract city"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Numero de cuenta
          </label>
          <input
            type="text"
            value={bankAccountNumber}
            onChange={(e) => setBankAccountNumber(e.target.value)}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Enter bank account number"
          />
        </div>
        
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Nombre del banco
          </label>
          <Select
            value={selectedBankId.toString()}
            onValueChange={(value) => setSelectedBankId(value)}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Seleccionar banco" />
            </SelectTrigger>
            <SelectContent className="h-40">
              {banks.map((bank) => (
                <SelectItem key={bank.id} value={bank.id.toString()}>
                  {bank.bankName} ({bank.location})
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        
        {timeValue && (
          <div className="border p-4 rounded-md bg-gray-50">
            <h3 className="font-medium mb-2">Información de valor por hora:</h3>
            <p><span className="font-medium">Nombre:</span> {timeValue.name}</p>
            <p><span className="font-medium">Valor hora virtual:</span> ${timeValue.virtualHourValue}</p>
            <p><span className="font-medium">Valor hora presencial:</span> ${timeValue.inpersonHourValue}</p>
            <p><span className="font-medium">Valor hora virtual cancelada:</span> ${timeValue.virtualHourCancelledValue}</p>
            <p><span className="font-medium">Valor hora presencial cancelada:</span> ${timeValue.inpersonHourCancelledValue}</p>

          </div>
        )}
        
        <div className="pt-4">
          <Dialog>
            <DialogTrigger asChild>
              <Button variant="ghost">Cambiar valor hora profesores</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
              <CrearEditarTimeValueProfesores 
                data={timeValue || {}} 
                context={"modificar"} 
              />
            </DialogContent>
          </Dialog>
        </div>

        <div className="pt-4">
          <Button
            className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 rounded-md"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default CrearEditarProfesorBankData;
