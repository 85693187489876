import React, { useState } from "react";
import { Button } from "../../../../ui/button";
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/dialog";
import { Input } from "../../../../ui/input";
import Loader from "../../../../Loader/Loader";
import { Textarea } from "../../../../ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../ui/select";
import { postORputClass } from "../../../../../provider/adm/Clases/postORputIndividualClass";
import { toast } from "sonner";
import ScrollListProfesores from "../../../Grupos/ScrollListProfesores";

const ModificarClases = ({
  data,
  studentID,
  profesorAsignado,
  teacherID_fromButton,
}) => {
  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // Initialize states based on provided data or defaults
  const [classStatus, setClassStatus] = useState(
    (data.classStatus === "Cancelled" && data.duration !== 0
      ? "Parcial"
      : data.classStatus) || "To be held"
  );
  const [date, setDate] = useState(
    data.dateTime ? formatDateForInput(data.dateTime) : ""
  );
  const [classType, setClassType] = useState(data.classType || "Virtual");
  const [duration, setDuration] = useState(data.duration || 0);
  const [plannedDuration, setPlannedDuration] = useState(
    data.plannedDuration || 1
  );
  const [comments, setComments] = useState(data.comment || "");
  const [topics, setTopics] = useState(data.topic || "");
  const [durationCancelled, setDurationCancelled] = useState(
    data.classCancellation?.durationCancelled || 0
  );
  const [cancellationTiming, setCancellationTiming] = useState(
    data.classCancellation?.cancellationTiming || "On time"
  );
  const [canceledBy, setCanceledBy] = useState(
    data.classCancellation?.canceledBy || "Participant"
  );
  const [cancellationReason, setCancellationReason] = useState(
    data.classCancellation?.cancellationReason || ""
  );
  const [teacherID, setTeacherID] = useState(
    !teacherID_fromButton ? data.teacherId : teacherID_fromButton
  );
  const [teacherNameprev, setteacherNameprev] = useState(
    data.teacherName || ""
  );
  const [loading, setLoading] = useState(false);
  // Función para recargar datos
  const refreshData = async () => {
    if (window.refreshCalendarData) {
      await window.refreshCalendarData();
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "date":
        setDate(value);
        break;
      case "comments":
        setComments(value);
        break;
      case "topics":
        setTopics(value);
        break;
      case "cancellationReason":
        setCancellationReason(value);
        break;
      default:
        break;
    }
  };

  const handleStatusChange = (value) => {
    setClassStatus(value);

    // Reset cancellation data if changing from cancelled to another status
    if (value !== "Cancelled") {
      setDurationCancelled(0);
      setCancellationReason("");
    }

    // Reset duration if changing to "To be held"
    if (value === "To be held") {
      setDuration(0);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields based on class status
    if (classStatus === "Cancelled") {
      if (
        !cancellationReason ||
        !cancellationTiming ||
        !canceledBy ||
        !durationCancelled
      ) {
        toast.error("Por favor, completa todos los campos de cancelación.");
        return;
      }

      if (durationCancelled > plannedDuration) {
        toast.error(
          "Las horas canceladas no pueden ser mayores que las horas planeadas."
        );
        return;
      }
    }

    if (classStatus === "Held" && plannedDuration !== duration) {
      toast.error(
        "Revisar la duracion de la clase, debe coincidir con las horas planeadas"
      );
      return;
    }
    if (
      classStatus === "Parcial" &&
      plannedDuration !== duration + durationCancelled
    ) {
      toast.error(
        "Revisar la duracion real de la clase y la cantidad de horas canceladas, debe ser igual a las horas planeadas"
      );
      return;
    }
    if (classStatus === "Cancelled" && plannedDuration !== durationCancelled) {
      toast.error(
        "Revisar la cantidad de horas canceladas, debe ser igual a las horas planeadas"
      );
      return;
    }

    if (!teacherID || !profesorAsignado) {
      toast.error("Por favor, selecciona un profesor.");
      return;
    }

    if (!date) {
      toast.error("Por favor, selecciona una fecha y hora.");
      return;
    }

    setLoading(true);
    const formatDate = (date) => {
      const isoString = date.toISOString();
      const formattedDate = isoString.replace("Z", "000000Z");
      return formattedDate;
    };

    // Prepare adminClassDTO
    const adminClassDTO = {
      teacherID: teacherID,
      classType: classType,
      dateTime: formatDate(new Date(date)),
      duration: parseFloat(duration),
      participantID: data.participantID || studentID,
      classScope: "Individual",
      comment: comments,
      topic: topics,
      classStatus: classStatus === "Parcial" ? "Cancelled" : classStatus,
      plannedDuration: parseFloat(plannedDuration),
    };

    // Prepare classCancellationDTO if class is cancelled
    let classCancellationDTO = null;
    if (classStatus === "Cancelled" || classStatus === "Parcial") {
      classCancellationDTO = {
        durationCancelled: parseFloat(durationCancelled),
        cancellationReason: cancellationReason,
        cancellationTiming: cancellationTiming,
        canceledBy: canceledBy,
        classID: data.id || null,
      };
    }

    // Prepare final request payload
    const requestPayload = classCancellationDTO
      ? { adminClassDTO, classCancellationDTO }
      : { adminClassDTO };

    try {
      await postORputClass(requestPayload, data.id);
      toast.success(
        data.id ? "Clase actualizada exitosamente" : "Clase creada exitosamente"
      );
      await refreshData();
    } catch (error) {
      console.error("Error updating/creating class:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="overflow-y-auto max-h-[70vh] p-6">
      {loading && <Loader />}

      <DialogHeader>
        <DialogTitle>
          {data.id ? "Modificar clase" : "Crear nueva clase"}
        </DialogTitle>
        <DialogDescription>
          {data.id
            ? "Modifique los datos que requiera de la clase"
            : "Complete los datos para crear una nueva clase"}
        </DialogDescription>
      </DialogHeader>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {data.id ? (
          // Solo mostrar el selector de estado para clases existentes
          <div className="flex flex-col">
            <label className="mb-2 font-semibold">
              Estado de la clase:
              <Select onValueChange={handleStatusChange} value={classStatus}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Seleccione estado" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Held">Completada</SelectItem>
                  <SelectItem value="Cancelled">Cancelada</SelectItem>
                  <SelectItem value="Parcial">Parcial</SelectItem>
                </SelectContent>
              </Select>
            </label>
          </div>
        ) : (
          // Para clases nuevas, mostrar el estado fijo
          <div className="flex flex-col">
            <label className="mb-2 font-semibold">
              Estado de la clase:
              <div className="p-2 mt-1 border rounded-md bg-gray-100">
                Por realizar (fijo para clases nuevas)
              </div>
            </label>
          </div>
        )}

        {/* Resto del código sigue igual */}

        {data.id ? (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Profesor asignado
            </label>
            <ScrollListProfesores
              setTeacherID={setTeacherID}
              setprofesorSelectedToFilter={setteacherNameprev}
              profesorSelectedToFilter={teacherNameprev}
              setLoading={setLoading}
            />
          </div>
        ) : (
          <div className="flex flex-col">
            <label className="mb-2 font-semibold">
              Profesor asignado:
              <div className="p-2 mt-1 border rounded-md bg-gray-100">
                {profesorAsignado}
              </div>
            </label>
          </div>
        )}

        <div className="flex flex-col">
          <label className="mb-2 font-semibold">
            Fecha y hora de inicio:
            <Input
              type="datetime-local"
              name="date"
              value={date}
              onChange={handleChange}
            />
          </label>
        </div>

        <div className="flex flex-col">
          <label className="mb-2 font-semibold">
            Tipo de clase:
            <Select
              name="classType"
              onValueChange={setClassType}
              value={classType}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Tipo de clase" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="Virtual">Virtual</SelectItem>
                <SelectItem value="In-person">Presencial</SelectItem>
              </SelectContent>
            </Select>
          </label>
        </div>

        <div className="flex flex-col">
          <label className="mb-2 font-semibold">
            Duración planificada (horas):
            <Select
              name="plannedDuration"
              onValueChange={(value) => setPlannedDuration(parseFloat(value))}
              value={plannedDuration.toString()}
            >
              <SelectTrigger className="w-full">
                <SelectValue placeholder="Duración planificada" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="0">0 horas</SelectItem>
                <SelectItem value="1">1 hora</SelectItem>
                <SelectItem value="1.5">1:30 horas</SelectItem>
                <SelectItem value="2">2 horas</SelectItem>
                <SelectItem value="2.5">2:30 horas</SelectItem>
                <SelectItem value="3">3 horas</SelectItem>
                <SelectItem value="3.5">3:30 horas</SelectItem>
                <SelectItem value="4">4 horas</SelectItem>
              </SelectContent>
            </Select>
          </label>
        </div>

        {(classStatus === "Held" || classStatus === "Parcial") && (
          <div className="flex flex-col">
            <label className="mb-2 font-semibold">
              Duración real (horas):
              <Select
                name="Duration"
                onValueChange={(value) => setDuration(parseFloat(value))}
                value={duration.toString()}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Duración real" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="0">0 horas</SelectItem>
                  <SelectItem value="1">1 hora</SelectItem>
                  <SelectItem value="1.5">1:30 horas</SelectItem>
                  <SelectItem value="2">2 horas</SelectItem>
                  <SelectItem value="2.5">2:30 horas</SelectItem>
                  <SelectItem value="3">3 horas</SelectItem>
                  <SelectItem value="3.5">3:30 horas</SelectItem>
                  <SelectItem value="4">4 horas</SelectItem>
                </SelectContent>
              </Select>
            </label>
          </div>
        )}

        <div className="flex flex-col md:col-span-2">
          <label className="mb-2 font-semibold">
            Comentarios:
            <Textarea
              name="comments"
              value={comments}
              onChange={handleChange}
              className="w-full"
            />
          </label>
        </div>

        <div className="flex flex-col md:col-span-2">
          <label className="mb-2 font-semibold">
            Temas cubiertos:
            <Textarea
              name="topics"
              value={topics}
              onChange={handleChange}
              className="w-full"
            />
          </label>
        </div>

        {(classStatus === "Cancelled" || classStatus === "Parcial") && (
          <div className="cancellation md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="flex flex-col">
              <label className="mb-2 font-semibold">
                ¿Cuántas horas fueron canceladas?
                <Select
                  name="durationCancelled"
                  onValueChange={(value) =>
                    setDurationCancelled(parseFloat(value))
                  }
                  value={durationCancelled.toString()}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Horas canceladas" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="0">0 horas</SelectItem>
                    <SelectItem value="1">1 hora</SelectItem>
                    <SelectItem value="1.5">1:30 horas</SelectItem>
                    <SelectItem value="2">2 horas</SelectItem>
                    <SelectItem value="2.5">2:30 horas</SelectItem>
                    <SelectItem value="3">3 horas</SelectItem>
                    <SelectItem value="3.5">3:30 horas</SelectItem>
                    <SelectItem value="4">4 horas</SelectItem>
                  </SelectContent>
                </Select>
              </label>
            </div>

            <div className="flex flex-col">
              <label className="mb-2 font-semibold">
                ¿La cancelación fue a tiempo?
                <Select
                  name="cancellationTiming"
                  onValueChange={setCancellationTiming}
                  value={cancellationTiming}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Tipo de cancelación" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="On time">A tiempo</SelectItem>
                    <SelectItem value="Late">Tarde</SelectItem>
                  </SelectContent>
                </Select>
              </label>
            </div>

            <div className="flex flex-col">
              <label className="mb-2 font-semibold">
                ¿Quién canceló la clase?
                <Select
                  name="canceledBy"
                  onValueChange={setCanceledBy}
                  value={canceledBy}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Cancelado por" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Participant">Estudiante</SelectItem>
                    <SelectItem value="Teacher">Profesor</SelectItem>
                  </SelectContent>
                </Select>
              </label>
            </div>

            <div className="flex flex-col md:col-span-2">
              <label className="mb-2 font-semibold">
                Motivo de la cancelación:
                <Textarea
                  name="cancellationReason"
                  value={cancellationReason}
                  onChange={handleChange}
                  className="w-full"
                />
              </label>
            </div>
          </div>
        )}
      </div>
      <DialogFooter className="mt-4">
        <Button
          type="button"
          variant="ghost"
          onClick={handleSubmit}
          className="w-full"
        >
          {data.id ? "Guardar cambios" : "Crear clase"}
        </Button>
      </DialogFooter>
    </div>
  );
};

export default ModificarClases;
