import { toast } from 'sonner';

export async function crearAttendance(dto) {
    const token = sessionStorage.getItem('token');

    try {
        // Build the URL
        const url = `${process.env.REACT_APP_API_URL}/profesor/clase/crear/asistencias`;

        console.log("Sending attendance data:", JSON.stringify(dto));

        // Realizar la solicitud POST
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dto)
        });

        // Para depurar
        console.log("Response status:", response.status);
        console.log("Response headers:", response.headers);
        
        // Procesar la respuesta
        const contentType = response.headers.get('content-type');
        const responseText = await response.text();
        console.log("Response text:", responseText);

        if (!response.ok) {
            console.error('Failed to create attendance. Server responded with:', responseText);
            
            let errorMessage = "Error al registrar asistencia";
            let parsedError = null;
            
            // Try to parse the error response
            if (contentType && contentType.includes('application/json') && responseText) {
                try {
                    parsedError = JSON.parse(responseText);
                    if (parsedError.message) {
                        errorMessage = parsedError.message;
                    }
                } catch (e) {
                    // Keep default error message if parsing fails
                }
            }
            
            // Set specific error messages based on status code
            switch (response.status) {
                case 400:
                    toast.error("Datos inválidos. Comprueba la información proporcionada.");
                    break;
                case 401:
                    toast.error("No autorizado. Inicia sesión nuevamente.");
                    sessionStorage.removeItem('token'); // Clear invalid token
                    break;
                case 403:
                    toast.error("No tienes permisos para registrar asistencias.");
                    break;
                case 404:
                    toast.error("Recurso no encontrado. Verifica los datos de la clase.");
                    break;
                case 409:
                    toast.error("Ya existe un registro de asistencia para esta clase.");
                    break;
                case 500:
                    toast.error("Error del servidor. Intenta más tarde.");
                    break;
                default:
                    toast.error(errorMessage || "Error al registrar asistencia.");
            }
            
            throw new Error(`Failed to create attendance: ${response.status} ${response.statusText} - ${errorMessage}`);
        }

        let parsedResponse;
        if (contentType && contentType.includes('application/json') && responseText) {
            try {
                parsedResponse = JSON.parse(responseText);
            } catch (e) {
                console.log("Response is not valid JSON, returning as text");
                parsedResponse = { message: responseText || "Attendance created successfully" };
            }
        } else {
            parsedResponse = { message: responseText || "Attendance created successfully" };
        }
        
        toast.success("Asistencia registrada con éxito");
        return parsedResponse;
    } catch (error) {
        // Handle network errors or other exceptions outside of the response handling
        if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
            toast.error("Error de conexión. Verifica tu conexión a internet.");
            console.error('Network error registering attendance:', error);
        } else if (!error.message.includes('Failed to create attendance')) {
            // Only show generic error if not already handled in the response section
            toast.error("Error al crear la asistencia. Por favor, inténtalo nuevamente.");
            console.error('Error registrando asistencia:', error);
        } else {
            // Error was already logged and displayed above, just log it here
            console.error('Error details:', error);
        }
        throw error;
    }
}