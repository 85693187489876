import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import NavMobile from "../../Nav/NavMobile";
import NavWeb from "../../Nav/NavWeb";
import { Button } from "../../../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import { TrashIcon } from "@radix-ui/react-icons";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../ui/alert-dialog";
import "./ProfesorDetalle.css";
import { Dialog, DialogContent, DialogTrigger } from "../../../ui/dialog";
import { BellIcon, DownloadIcon } from "@radix-ui/react-icons";
import Calendar from "../../EstudiantesPrivados/Dashboard/Calendar";
import Loader from "../../../Loader/Loader";
import ClickOnClassTeamAdmin from "./ClickOnClassTeamAdmin";
import ClickOnClassEstudentDetailAdmin from "./ClickOnClassEstudentDetailAdmin";
import CrearEditarProfesorBankData from "../CrearEditProfesorBankData";
import { getClassesByTeacherAndDateTimeBetween } from "../../../../provider/adm/Clases/getClassesByTeacherAndDateTimeBetween";
import { dashboardTeacher } from "../../../../provider/adm/dashboard/dashboardTeacher";
import { Toaster } from "sonner";
import { deleteClass } from "../../../../provider/adm/Clases/deleteClass";
const ProfesoresDashboard = () => {
  const [profesorData, setProfesorData] = useState(null);
  const [classes_estudiante, setClassesEstudiante] = useState([]);
  const [teacherInfoClasses, setTeacherInfoClasses] = useState([]);
  const [date, setDate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // Función para recargar datos
  const refreshData = async () => {
    if (window.refreshCalendarData) {
      await window.refreshCalendarData();
    } else {
      // Fallback: incrementa el trigger para forzar la recarga
      setRefreshTrigger((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = localStorage.getItem("selected_teacher");
      if (data) {
        setProfesorData(JSON.parse(data));
      }
      const storedDate = localStorage.getItem("teacher_dashboard_date");
      if (storedDate) {
        setDate(JSON.parse(storedDate));
      } else {
        setDate({
          month: parseInt(new Date().getMonth().toString()) + 1,
          year: new Date().getFullYear().toString(),
        });
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (date && date.month && date.year) {
      localStorage.setItem("teacher_dashboard_date", JSON.stringify(date));
    }
  }, [date]);

  const exportToExcel = async () => {
    try {
      setLoading(true);
  
      // Use the current date state values for the export
      const { year, month } = date;
  
      // Create the API URL with query parameters
      const apiUrl = `${process.env.REACT_APP_API_URL}/admin/profesor/exportar-profesores?year=${year}&month=${month}`;
      const token = sessionStorage.getItem('token'); // Retrieve the JWT token from session storage
  
      // Fetch the Excel file from the API
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`, 
          Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error al exportar: ${response.status}`);
      }
  
      // Get the file as a blob
      const blob = await response.blob();
      
      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);
      
      // Create filename
      const fileName = `Profesores_${year}_${month}.xlsx`;
      
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.setAttribute('download', fileName);
      
      // Set additional attributes to try to force the save dialog
      link.setAttribute('type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      link.setAttribute('target', '_blank');
      
      // Append to the document, trigger click, and then remove
      document.body.appendChild(link);
      link.click();
      
      // Remove the element and revoke the object URL to free up memory
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 100);
      
    } catch (error) {
      console.error("Error al exportar el archivo Excel:", error);
      // You could use the Toaster here to show an error message
    } finally {
      setLoading(false);
    }
  };

  if (!profesorData) {
    return <Loader />;
  }

  return (
    <div
      className="min-h-screen flex"
      style={{ overflowY: "hidden", height: "100vh" }}
    >
      {loading && <Loader />}
      <NavMobile />
      <NavWeb />
      <div className="dashboardprofesor">
        <div className="dashboardcontainergroup">
          <div className="tituloynotificaciones">
            <h2 className="text-xl font-bold text-gray-900">
              Profesor {profesorData.fullName} con id {profesorData.id}
            </h2>
            <BellIcon className="h-6 w-6" />
          </div>
          <div className="filtrosandbackbtn">
            <a href="/admin/profesores/activos">
              <Button>Back</Button>
            </a>
            <div className="actions">
              <Calendar
                setDate={(date) => setDate(date)}
                date={date}
                ID={profesorData.id}
                setClasses={setClassesEstudiante}
                getClasses={getClassesByTeacherAndDateTimeBetween}
                getInforDashboard={dashboardTeacher}
                setInforDashboard={setTeacherInfoClasses}
                setLoading={setLoading}
                refreshTrigger={refreshTrigger}
              />

              <Button
                onClick={() => {
                  const currentDate = new Date();
                  const dateObj = {
                    month: (currentDate.getMonth() + 1).toString(),
                    year: currentDate.getFullYear().toString(),
                  };
                  setDate(dateObj);
                  localStorage.removeItem("teacher_dashboard_date");
                }}
              >
                limpiar filtros
              </Button>
              <Button onClick={exportToExcel}>
                <DownloadIcon />
                Exportar
              </Button>
            </div>
          </div>
          <div className="resumenDeActividadAcademica">
            <div className="actividadCard">
              <h3>Núm de horas canceladas tarde virtual</h3>
              <p className="total">
                {teacherInfoClasses.hoursCanceledParticipantLateVirtual}
              </p>
            </div>
            <div className="actividadCard">
              <h3>Núm de horas canceladas tarde presencial </h3>
              <p className="total">
                {teacherInfoClasses.hoursCanceledParticipantLateInPerson}
              </p>
            </div>
            <div className="actividadCard">
              <h3>Total de horas planeadas del mes </h3>
              <p className="total">{teacherInfoClasses.hoursPlanned}</p>
            </div>
            <div className="actividadCard">
              <h3>Total horas virtuales</h3>
              <p className="total">{teacherInfoClasses.hoursHeldVirtual}</p>
            </div>
            <div className="actividadCard">
              <h3>Total horas presenciales</h3>
              <p className="total">{teacherInfoClasses.hoursHeldInPerson}</p>
            </div>
            <div className="actividadCard">
              <h3>Total de clases dictadas</h3>
              <p className="total">{teacherInfoClasses.hoursHeld}</p>
            </div>
          </div>
          <div className="informacionDetalladaEstudiante">
            <CrearEditarProfesorBankData personal_info_teacher={profesorData} />
          </div>
          <div className="totales bg-white shadow-md rounded-lg p-6 text-sm">
            {" "}
            {/* Añadido text-sm para reducir el tamaño de la letra */}
            <h2 className="text-2xl font-bold mb-4">Totales</h2>
            <div className="invoice space-y-4">
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">Horas Totales:</span>
                <span>{teacherInfoClasses.hoursHeld || 0} horas</span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">Horas Virtuales:</span>
                <span>{teacherInfoClasses.hoursHeldVirtual || 0} horas</span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">Horas Presenciales:</span>
                <span>{teacherInfoClasses.hoursHeldInPerson || 0} horas</span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">
                  Tarde Canceladas por Estudiante (Virtual):
                </span>
                <span>
                  {teacherInfoClasses.hoursCanceledParticipantLateVirtual || 0}{" "}
                  horas
                </span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">
                  Tarde Canceladas por Estudiante (Presencial):
                </span>
                <span>
                  {teacherInfoClasses.hoursCanceledParticipantLateInPerson || 0}{" "}
                  horas
                </span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">
                  Horas Canceladas por Profesor:
                </span>
                <span>
                  {teacherInfoClasses.hoursCanceledTeacherLate || 0} horas
                </span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">Valor Total Virtual:</span>
                <span>
                  {new Intl.NumberFormat("es-CO", {
                    style: "currency",
                    currency: "COP",
                  }).format(
                    (teacherInfoClasses.totalVirtualValue || 0).toFixed(2)
                  )}
                </span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">Valor Total Presencial:</span>
                <span>
                  {new Intl.NumberFormat("es-CO", {
                    style: "currency",
                    currency: "COP",
                  }).format(
                    (teacherInfoClasses.totalInPersonValue || 0).toFixed(2)
                  )}
                </span>
              </div>
              <div className="invoice-item flex justify-between text-xl font-bold mt-4">
                <span>Total Ganado:</span>
                <span>
                  {new Intl.NumberFormat("es-CO", {
                    style: "currency",
                    currency: "COP",
                  }).format(
                    (teacherInfoClasses.totalVirtualValue || 0) +
                      (teacherInfoClasses.totalInPersonValue || 0)
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="ultimas_clases_estudiante">
            <h2>
              <b>Clases de los participantes</b>
            </h2>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>ID clase</TableHead>
                  <TableHead>Fecha</TableHead>
                  <TableHead>Participante ID</TableHead>
                  <TableHead>Participante</TableHead>
                  <TableHead>Modalidad</TableHead>
                  <TableHead>H. planeadas</TableHead>
                  <TableHead>Duracion</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {classes_estudiante.map((classData) => (
                  <TableRow key={classData.id}>
                    <TableCell>{classData.id}</TableCell>
                    <TableCell>
                      {new Date(classData.dateTime).toLocaleString("es", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      })}
                    </TableCell>
                    <TableCell>{classData.participantID}</TableCell>
                    <TableCell>{classData.classScope}</TableCell>
                    <TableCell>{classData.classType}</TableCell>
                    <TableCell>{classData.plannedDuration} H</TableCell>
                    <TableCell>{classData.duration} H</TableCell>
                    <TableCell>
                      <div
                        className={`flex items-center justify-center p-1 rounded-lg text-white font-semibold ${
                          classData.classStatus === "Held"
                            ? "bg-green-500"
                            : classData.classStatus === "To be held"
                            ? "bg-gray-500"
                            : classData.duration > 0
                            ? "bg-orange-500"
                            : "bg-red-500"
                        }`}
                      >
                        <span className="w-2 h-2 rounded-full mr-3 bg-white"></span>
                        <span>
                          {classData.classStatus === "To be held"
                            ? "Por llenar"
                            : classData.classStatus === "Held"
                            ? "Completada"
                            : classData.duration > 0
                            ? "Parcial"
                            : "Cancelada"}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell className="flex space-x-2">
                      {/* AlertDialog for delete action */}
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <button onClick={(e) => e.stopPropagation()}>
                            <TrashIcon className="w-6 h-6 text-gray-400" />
                          </button>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>
                              ¿Estás absolutamente seguro?
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                              Esta acción no se puede deshacer. Esto eliminará
                              permanentemente la clase y todos sus datos
                              asociados.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancelar</AlertDialogCancel>
                            <AlertDialogAction
                              onClick={async () => {
                                setLoading(true);
                                try {
                                  if (classData.classScope === "Team") {
                                    await deleteClass(classData.id, false);
                                  } else{
                                    const confirmReturnHours = window.confirm(
                                      "¿Deseas devolver las horas al estudiante?"
                                    );
                                    await deleteClass(classData.id,confirmReturnHours);
                                  }
                                  await refreshData();
                                } catch (error) {
                                  console.error(
                                    "Error eliminando la clase:",
                                    error
                                  );
                                } finally {
                                  setLoading(false);
                                }
                              }}
                            >
                              Sí, eliminar
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>

                      {/* Separate Dialog for viewing participant details */}
                      <Dialog>
                        <DialogTrigger asChild>
                          <button>
                            <span className="text-sm text-blue-600 cursor-pointer">
                              Ver detalles
                            </span>
                          </button>
                        </DialogTrigger>
                        <DialogContent className="sm:max-w-[425px] p-4">
                          {classData.classScope === "Team" ? (
                            <ClickOnClassTeamAdmin
                              teamID={classData.participantID}
                            />
                          ) : (
                            <ClickOnClassEstudentDetailAdmin
                              studentId={classData.participantID}
                            />
                          )}
                        </DialogContent>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default ProfesoresDashboard;
