import React, { useState, useEffect } from "react";
import { Toaster, toast } from "sonner";
import Loader from "../../Loader/Loader";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";

const HoursHistory = ({ idStudent }) => {
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [totalHours, setTotalHours] = useState(0);

  const monthNames = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  // Format date to a readable format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    
    return `${day} de ${month}, ${year} - ${hours}:${minutes}`;
  };

  useEffect(() => {
    fetchHoursHistory();
  }, [year, month]);

  const fetchHoursHistory = async () => {
    try {
      setLoading(true);
      const token = sessionStorage.getItem('token');
      
      if (!token) {
        toast.error("Sesión inválida. Inicia sesión nuevamente");
        return;
      }
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/estudiante/personalizado/horas?studentID=${idStudent}&year=${year}&month=${month}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
      
      const data = await response.json();
      setHistoryData(data);
      
      // Calculate total hours
      const total = data.reduce((sum, item) => sum + item.hours, 0);
      setTotalHours(total);
    } catch (error) {
      console.error("Error fetching hours history:", error);
      toast.error("Error al cargar el historial de horas");
    } finally {
      setLoading(false);
    }
  };

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear - 2; i <= currentYear + 1; i++) {
    years.push(i);
  }

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Historial de Horas Compradas</h2>
      
      <div className="flex gap-4 mb-4">
        <div className="w-1/2">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Año
          </label>
          <Select value={year.toString()} onValueChange={(value) => setYear(parseInt(value))}>
            <SelectTrigger>
              <SelectValue placeholder="Seleccionar año" />
            </SelectTrigger>
            <SelectContent>
              {years.map(y => (
                <SelectItem key={y} value={y.toString()}>{y}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        
        <div className="w-1/2">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Mes
          </label>
          <Select value={month.toString()} onValueChange={(value) => setMonth(parseInt(value))}>
            <SelectTrigger>
              <SelectValue placeholder="Seleccionar mes" />
            </SelectTrigger>
            <SelectContent>
              {monthNames.map((name, index) => (
                <SelectItem key={index} value={(index + 1).toString()}>{name}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>

      {loading ? (
        <Loader />
      ) : historyData.length > 0 ? (
        <>
          <div className="bg-blue-50 p-3 rounded-md mb-4">
            <p className="font-semibold">Total de horas compradas en este período: <span className="text-blue-600">{totalHours} horas</span></p>
          </div>
          
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200 rounded-lg">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-2 px-4 border-b text-left">Fecha</th>
                  <th className="py-2 px-4 border-b text-left">Horas</th>
                  <th className="py-2 px-4 border-b text-left">ID Admin</th>
                </tr>
              </thead>
              <tbody>
                {historyData.map((item) => (
                  <tr key={item.id} className="hover:bg-gray-50">
                    <td className="py-2 px-4 border-b">{formatDate(item.date)}</td>
                    <td className="py-2 px-4 border-b">{item.hours}</td>
                    <td className="py-2 px-4 border-b">{item.adminID}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <div className="text-center py-8 text-gray-500">
          No hay registros de compras de horas para este período
        </div>
      )}
      <Toaster />
    </div>
  );
};

export default HoursHistory;