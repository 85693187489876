import { toast } from 'sonner';

export async function postOrPutAttendance(attendance, id) {
    const token = sessionStorage.getItem('token');

    try {
        const puturl = `${process.env.REACT_APP_API_URL}/admin/clase/equipo/asistencia/actualizar`;

        // Realizar la solicitud PUT
        const putResponse = await fetch(puturl, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(attendance) // Puede contener adminClassDTO o adminClassDTO+classCancellationDTO
        });

        // Procesar la respuesta PUT
        const contentType = putResponse.headers.get('content-type');
        const responseText = await putResponse.text();

        if (!putResponse.ok) {
            console.error('Failed to update class. Server responded with:', responseText);
            throw new Error('Failed to update class');
        }

        if (contentType && contentType.includes('application/json')) {
            return JSON.parse(responseText);
        } else {
            toast.success("Clase actualizada con éxito");
            return { message: responseText };
        }
        // Si no hay ID, es una creación (POST)
    } catch (error) {
        try {
            const posturl = `${process.env.REACT_APP_API_URL}/admin/clase/equipo/asistencia/crear`;
            // Realizar la solicitud POST
            const postResponse = await fetch(posturl, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(attendance)
            });

            // Procesar la respuesta POST
            const contentType = postResponse.headers.get('content-type');
            const responseText = await postResponse.text();

            if (!postResponse.ok) {
                console.error('Failed to create class. Server responded with:', responseText);
                throw new Error('Failed to create class');
            }

            if (contentType && contentType.includes('application/json')) {
                return JSON.parse(responseText);
            } else {
                toast.success("Clase creada con éxito");
                return { message: responseText };
            }
        } catch (error) {
            toast.error("Error al actualizar o crear la clase. Por favor, revisa todos los campos.", error);
            console.error('Error processing class request:', error);
            throw error;
        }
    }
}