"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useEffect } from "react";
import { cn } from "../../../../Lib/utils";
import { Button } from "../../../ui/button";
import { Calendar } from "../../../ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../../../ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "../../../ui/popover";
export default function CalendarForm({
  setDate,
  date,
  ID,
  setClasses,
  getClasses,
  setClasses2,
  getClasses2,
  getInforDashboard,
  setInforDashboard,
  getInfoTeacher,
  getClassesWithFirstandEndDate,
  setLoading,
  refreshTrigger = 0, // Añadir este prop para forzar recargas
}) {
  const FormSchema = z.object({
    dob: z.date({
      required_error: "A date of birth is required.",
    }),
  });
  const form = useForm({
    resolver: zodResolver(FormSchema),
  });

  // Función para recargar los datos
  const fetchClasses = async () => {
    try {
      if (!ID) {
        return;
      }
      
      if (getClasses) {
        setLoading(true);
        const clases = await getClasses(ID, date.year, date.month);
        if (clases) {
          setClasses(clases);
        }
      }
      
      if (setClasses2 && getClasses2) {
        setLoading(true);
        const clases2 = await getClasses2(ID, date.year, date.month);
        if (clases2) {
          setClasses2(clases2);
        }
      }
      if (getClassesWithFirstandEndDate) {
        const firstDayOfMonth = new Date(
          date.year,
          date.month - 1,
          1
        ).toISOString();
        const lastDayOfMonth = new Date(
          date.year,
          date.month,
          0,
          23,
          59,
          59,
          999
        ).toISOString();
        const clases = await getClassesWithFirstandEndDate(
          firstDayOfMonth,
          lastDayOfMonth
        );
        if (clases) {
          setClasses(clases);
        }
      }
      if (getInforDashboard) {
        setLoading(true);
        const info = await getInforDashboard(ID, date.year, date.month);
        if (info) {
          setInforDashboard(info);
        }
      }
      if (getInfoTeacher) {
        const info = await getInfoTeacher(date.year, date.month);
        if (info) {
          setInforDashboard(info);
        }
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setLoading(false);
    }
  };

  // Efecto para cargar datos cuando cambie date, ID o refreshTrigger
  useEffect(() => {
    fetchClasses();
  }, [
    ID,
    date,
    refreshTrigger, // Añade esta dependencia
    setClasses,
    getClasses,
    setClasses2,
    getClasses2,
    getInforDashboard,
    setInforDashboard,
    setLoading,
    getClassesWithFirstandEndDate,
    getInfoTeacher,
  ]);

  // Exponer función de recarga
  useEffect(() => {
    // Si el componente padre proporciona una función para registrar el método de recarga
    if (typeof window !== 'undefined') {
      window.refreshCalendarData = fetchClasses;
    }
    
    return () => {
      // Limpiar al desmontar
      if (typeof window !== 'undefined') {
        delete window.refreshCalendarData;
      }
    };
  }, []);

  // Convert the incoming date prop to a Date object
  const initialDate = new Date(
    parseInt(date.year),
    parseInt(date.month) - 1,
    1
  ); // Restar 1 al mes
  form.setValue("dob", initialDate); // Set the initial value for the form

  return (
    <Form {...form}>
      <form>
        <FormField
          control={form.control}
          name="dob"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={"outline"}
                      className={cn(
                        "w-[240px] pl-3 text-left font-normal",
                        !field.value && "text-muted-foreground"
                      )}
                    >
                      {field.value ? (
                        format(field.value, "MMM yyyy") // Format to show month and year
                      ) : (
                        <span>Pick a month and year</span>
                      )}
                      <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="single"
                    selected={field.value}
                    onSelect={(selectedDate) => {
                      if (selectedDate) {
                        const newDate = new Date(
                          selectedDate.getFullYear(),
                          selectedDate.getMonth(),
                          1
                        );
                        field.onChange(newDate);
                        const dateObj = {
                          month: (newDate.getMonth() + 1).toString(),
                          year: newDate.getFullYear().toString(),
                        };
                        setDate(dateObj);
                      } else {
                        console.error("No date selected");
                      }
                    }}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />
        {
          //<Button type="submit">Submit</Button>
        }
      </form>
    </Form>
  );
}
