import React, { useState } from "react";
import NavMobile from "../Nav/NavMobile";
import NavWeb from "../Nav/NavWeb";
import { Button } from "../../ui/button";
import "./DashBoard.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import { useLocation } from "react-router-dom";
import Calendar from "../../../Components/Administrativo/EstudiantesPrivados/Dashboard/Calendar";
import { BellIcon } from "@radix-ui/react-icons";
import Loader from "../../Loader/Loader";
import ParticipantInfoDialog from "../RegistrarHoras/ParticipantInfoDialog";
import { infoDashboardTeacher } from "../../../provider/profesor/infoDashboardTeacher";
import { getClassesByTeacherAndDateTimeBetweenDays } from "../../../provider/profesor/ListClasess/getClassesByTeacherAndDateTimeBetweenDays";
import { Toaster } from "sonner";

const DashBoardProfesor = () => {
  const [date, setDate] = useState({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear().toString(),
  });
  const [classes_participante, setclasses_participante] = useState([]);
  const [info, setInfo] = useState({});
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const teacherId = params.get("profesorId");
  const nombre = params.get("nombre");
  const [loading, setLoading] = useState(false);
  // Add state for participant info dialog
  const [participantInfoDialog, setParticipantInfoDialog] = useState({
    isOpen: false,
    participantId: null,
    participantType: null,
  });

  // Function to open participant info dialog
  const showParticipantInfo = (participantId, participantType) => {
    setParticipantInfoDialog({
      isOpen: true,
      participantId,
      participantType,
    });
  };

  // Function to close participant info dialog
  const closeParticipantInfo = () => {
    setParticipantInfoDialog({
      isOpen: false,
      participantId: null,
      participantType: null,
    });
  };

  // Render the loading state separately
  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className="min-h-screen flex"
      style={{ overflowY: "hidden", height: "100vh" }}
    >
      <NavMobile />
      <NavWeb />
      <div className="dashboardprofesorview">
        <div className="dashboardcontainergroup">
          {/* Add the ParticipantInfoDialog component */}
          <ParticipantInfoDialog
            isOpen={participantInfoDialog.isOpen}
            onClose={closeParticipantInfo}
            participantId={participantInfoDialog.participantId}
            participantType={participantInfoDialog.participantType}
          />

          <div className="tituloynotificaciones">
            <h2 className="text-xl font-bold text-gray-900">
              Teacher {nombre} with ID {teacherId}
            </h2>
            <BellIcon className="h-6 w-6" />
          </div>
          <div className="filtrosandbackbtn">
            <div className="actions">
              <Calendar
                setDate={(date) => setDate(date)}
                date={date}
                ID={teacherId}
                setClasses={setclasses_participante}
                getClassesWithFirstandEndDate={
                  getClassesByTeacherAndDateTimeBetweenDays
                }
                getInfoTeacher={infoDashboardTeacher}
                setInforDashboard={setInfo}
                setLoading={setLoading}
              />
              <Button
                onClick={() => {
                  const currentDate = new Date();
                  const dateObj = {
                    month: (currentDate.getMonth() + 1).toString(),
                    year: currentDate.getFullYear().toString(),
                  };
                  setDate(dateObj);
                }}
              >
                Clear Filters
              </Button>
            </div>
          </div>
          <div className="resumenDeActividadAcademica">
            <div className="actividadCard">
              <h3>Number of late canceled virtual hours</h3>
              <p className="total">
                {info.hoursCanceledParticipantLateVirtual}
              </p>
            </div>
            <div className="actividadCard">
              <h3>Number of late canceled in-person hours</h3>
              <p className="total">
                {info.hoursCanceledParticipantLateInPerson}
              </p>
            </div>
            <div className="actividadCard">
              <h3>Total planned hours for the month</h3>
              <p className="total">{info.hoursPlanned}</p>
            </div>
            <div className="actividadCard">
              <h3>Total virtual hours</h3>
              <p className="total">{info.hoursHeldVirtual}</p>
            </div>
            <div className="actividadCard">
              <h3>Total in-person hours</h3>
              <p className="total">{info.hoursHeldInPerson}</p>
            </div>
            <div className="actividadCard">
              <h3>Total classes taught</h3>
              <p className="total">{info.hoursHeld}</p>
            </div>
          </div>
          <div className="totales bg-white shadow-md rounded-lg p-6 text-sm">
            {" "}
            <h2 className="text-2xl font-bold mb-4">Totals</h2>
            <div className="invoice space-y-4">
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">Total Hours:</span>
                <span>{info.hoursHeld || 0} hours</span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">Virtual Hours:</span>
                <span>{info.hoursHeldVirtual || 0} hours</span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">In-person Hours:</span>
                <span>{info.hoursHeldInPerson || 0} hours</span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">
                  Late Cancellations by Student (Virtual):
                </span>
                <span>
                  {info.hoursCanceledParticipantLateVirtual || 0} hours
                </span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">
                  Late Cancellations by Student (In-person):
                </span>
                <span>
                  {info.hoursCanceledParticipantLateInPerson || 0} hours
                </span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">
                  Hours Canceled by Teacher:
                </span>
                <span>{info.hoursCanceledTeacherLate || 0} hours</span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">Virtual Total Value:</span>
                <span>
                  {new Intl.NumberFormat("es-CO", {
                    style: "currency",
                    currency: "COP",
                  }).format((info.totalVirtualValue || 0).toFixed(2))}
                </span>
              </div>
              <div className="invoice-item flex justify-between border-b pb-2">
                <span className="font-semibold">In-person Total Value:</span>
                <span>
                  {new Intl.NumberFormat("es-CO", {
                    style: "currency",
                    currency: "COP",
                  }).format((info.totalInPersonValue || 0).toFixed(2))}
                </span>
              </div>
              <div className="invoice-item flex justify-between text-xl font-bold mt-4">
                <span>Total Earnings:</span>
                <span>
                  {new Intl.NumberFormat("es-CO", {
                    style: "currency",
                    currency: "COP",
                  }).format(
                    (info.totalVirtualValue || 0) +
                      (info.totalInPersonValue || 0)
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="ultimas_clases_estudiante">
            <h2>
              <b>Classes with participants</b>
            </h2>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Class ID</TableHead>
                  <TableHead>Date</TableHead>
                  <TableHead>Participant ID</TableHead>
                  <TableHead>Participant</TableHead>
                  <TableHead>Modality</TableHead>
                  <TableHead>Planned H.</TableHead>
                  <TableHead>Duration</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {classes_participante.map((classData) => (
                  <TableRow key={classData.id}>
                    <TableCell>{classData.id}</TableCell>
                    <TableCell>
                      {new Date(classData.dateTime).toLocaleString("en", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      })}
                    </TableCell>
                    <TableCell>{classData.participantID}</TableCell>
                    <TableCell>{classData.classScope}</TableCell>
                    <TableCell>{classData.classType}</TableCell>
                    <TableCell>{classData.plannedDuration} H</TableCell>
                    <TableCell>{classData.duration} H</TableCell>
                    <TableCell>
                      <div
                        className={`flex items-center justify-center p-1 rounded-lg text-white font-semibold ${
                          classData.classStatus === "Held"
                            ? "bg-green-500"
                            : classData.classStatus === "To be held"
                            ? "bg-gray-500"
                            : classData.duration > 0
                            ? "bg-orange-500"
                            : "bg-red-500"
                        }`}
                      >
                        <span className="w-2 h-2 rounded-full mr-3 bg-white"></span>
                        <span>
                          {classData.classStatus === "To be held"
                            ? "Upcoming"
                            : classData.classStatus === "Held"
                            ? "Completed"
                            : classData.duration > 0
                            ? "Partial"
                            : "Canceled"}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() =>
                          showParticipantInfo(
                            classData.participantID,
                            classData.classScope
                          )
                        }
                      >
                        View info
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default DashBoardProfesor;
