import React, { useState, useEffect, useCallback } from "react";
import { Card, CardContent } from "../../ui/card";
import { ChevronDown, ChevronUp } from "lucide-react";
import { getClassesByTeacherAndDateTimeBetweenDays } from "../../../provider/profesor/ListClasess/getClassesByTeacherAndDateTimeBetweenDays";
import { updateClass } from "../../../provider/profesor/ListClasess/updateClass";
import { crearAttendance } from "../../../provider/profesor/ListClasess/crearAttendance";
import ClassItem from "./ClassItem";
import Loader from "../../Loader/Loader";
import { toast } from "sonner";
import ParticipantInfoDialog from "./ParticipantInfoDialog";

const ListClasses = ({ startDate, endDate }) => {
  const [expandedEventId, setExpandedEventId] = useState(null);
  const [expandedDates, setExpandedDates] = useState({});
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState({});
  const [formData, setFormData] = useState({});
  const [attendanceData, setAttendanceData] = useState({}); // New state for attendance
  const [participantInfoDialog, setParticipantInfoDialog] = useState({
    isOpen: false,
    participantId: null,
    participantType: null,
  });

  const fetchClasses = useCallback(async () => {
    if (!startDate || !endDate) return;

    setLoading(true);
    try {
      // Format dates for API
      const formattedStartDate = startDate.toISOString();
      const formattedEndDate = endDate.toISOString();

      const data = await getClassesByTeacherAndDateTimeBetweenDays(
        formattedStartDate,
        formattedEndDate
      );

      setClasses(data);

      // Initialize form data for each class
      const initialFormData = {};
      data.forEach((cls) => {
        initialFormData[cls.id] = {
          duration: cls.duration,
          comment: cls.comment,
          topic: cls.topic,
          classStatus: cls.classStatus,
          durationCancelled: cls.classCancellation?.durationCancelled || 0,
          cancellationReason: cls.classCancellation?.cancellationReason || "",
          cancellationTiming:
            cls.classCancellation?.cancellationTiming || "On time",
          canceledBy: cls.classCancellation?.canceledBy || "Teacher",
        };
      });
      setFormData(initialFormData);
    } catch (error) {
      console.error("Error fetching classes:", error);
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate]);

  // Fetch classes from API
  useEffect(() => {
    fetchClasses();
  }, [fetchClasses]);

  // Group classes by date
  const groupedClasses = classes.reduce((acc, cls) => {
    const dateStr = new Date(cls.dateTime).toLocaleDateString();
    if (!acc[dateStr]) {
      acc[dateStr] = [];
    }
    acc[dateStr].push(cls);
    return acc;
  }, {});

  const toggleExpand = (id) => {
    setExpandedEventId(expandedEventId === id ? null : id);
  };

  const toggleDate = (date) => {
    setExpandedDates((prev) => ({
      ...prev,
      [date]: !prev[date],
    }));
  };

  const toggleEditMode = (id) => {
    setEditMode((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleInputChange = (id, field, value) => {
    setFormData((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const handleStatusChange = (id, status) => {
    setFormData((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        classStatus: status,
      },
    }));
  };

  // Handle attendance changes
  const handleAttendanceChange = useCallback((classId, newAttendance) => {
    setAttendanceData((prev) => ({
      ...prev,
      [classId]: newAttendance,
    }));
  }, []);

  const handleSave = async (id) => {
    // Prepare the data for submission
    const classData = formData[id];
    if (!classData) return;

    // Get the class object
    const classObject = classes.find((cls) => cls.id === id);
    if (!classObject) return;
    if (
      classData.classStatus === "Held" &&
      parseFloat(classData.duration) !== parseFloat(classObject.plannedDuration)
    ) {
      toast.error(
        "You cannot change the class duration; it must equal the planned duration"
      );
      return;
    }

    if (
      classData.classStatus === "Cancelled" &&
      parseFloat(classData.duration) +
        parseFloat(classData.durationCancelled) !=
        classObject.plannedDuration
    ) {
      toast.error(
        "The sum of the class duration and the cancelled class duration must equal the planned duration"
      );
      return;
    }

    setLoading(true);
    const payload = {
      teacherClassDTO: {
        duration: parseFloat(classData.duration),
        comment: classData.comment,
        topic: classData.topic,
        classStatus:
          classData.classStatus === "Partial"
            ? "Cancelled"
            : classData.classStatus,
      },
    };

    // Add cancellation data if class is cancelled
    if (
      classData.classStatus === "Cancelled" ||
      classData.classStatus === "Partial"
    ) {
      payload.classCancellationDTO = {
        durationCancelled: parseFloat(classData.durationCancelled),
        cancellationReason: classData.cancellationReason,
        cancellationTiming: classData.cancellationTiming,
        canceledBy: classData.canceledBy,
        classID: id,
      };
    }

    try {
      // Update class info
      await updateClass(payload, id);

      // Submit attendance for team classes when "Held" status or "Cancelled" with duration > 0
      const shouldSubmitAttendance =
        classObject.classScope === "Team" &&
        (classData.classStatus === "Held" ||
          (classData.classStatus === "Cancelled" &&
            parseFloat(classData.duration) > 0));

      if (shouldSubmitAttendance) {
        // Debug the attendance data

        // Check if we have attendance data
        if (
          !attendanceData[id] ||
          Object.keys(attendanceData[id]).length === 0
        ) {
          toast.error(
            "No attendance data to send. Please mark student attendance."
          );
          setLoading(false);
          return;
        }

        // Prepare attendance data
        const attendancePayload = {
          classID: id,
          attendances: Object.keys(attendanceData[id]).map((studentId) => ({
            studentTeamID: parseInt(studentId),
            attended: attendanceData[id][studentId],
          })),
        };

        // Submit attendance
        try {
          await crearAttendance(attendancePayload);

          // Wait a moment before fetching updated class data
          await new Promise((resolve) => setTimeout(resolve, 500));
        } catch (attendanceError) {
          console.error("Error submitting attendance:", attendanceError);
          toast.error("Error saving student attendance");
        }
      }

      // Refresh class data
      await fetchClasses();
      toast.success("Class updated successfully");
    } catch (error) {
      toast.error("Error updating class");
      console.error("Error updating class:", error);
      return;
    } finally {
      setLoading(false);
    }

    // Toggle edit mode back off
    toggleEditMode(id);
  };

  const showParticipantInfo = (participantId, participantType) => {
    setParticipantInfoDialog({
      isOpen: true,
      participantId,
      participantType,
    });
  };

  const closeParticipantInfo = () => {
    setParticipantInfoDialog({
      isOpen: false,
      participantId: null,
      participantType: null,
    });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="space-y-4">
      <ParticipantInfoDialog
        isOpen={participantInfoDialog.isOpen}
        onClose={closeParticipantInfo}
        participantId={participantInfoDialog.participantId}
        participantType={participantInfoDialog.participantType}
      />

      {classes.length === 0 ? (
        <Card>
          <CardContent className="p-4 text-center">
            {startDate && endDate
              ? "No classes found in the selected date range"
              : "Select a date range to view classes"}
          </CardContent>
        </Card>
      ) : (
        <div>
          <div className="text-xl font-bold mb-4">
            Classes ({classes.length})
          </div>

          {Object.entries(groupedClasses).map(([date, events]) => (
            <Card key={date} className="mb-2 bg-gray-500 bg-opacity-5">
              <CardContent className="p-2">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleDate(date)}
                >
                  <h2 className="text-lg font-semibold">
                    {date} ({events.length} classes)
                  </h2>
                  {expandedDates[date] ? (
                    <ChevronUp className="h-5 w-5" />
                  ) : (
                    <ChevronDown className="h-5 w-5" />
                  )}
                </div>

                {expandedDates[date] && (
                  <div className="mt-4 space-y-2">
                    {events.map((cls) => (
                      <ClassItem
                        key={cls.id}
                        cls={cls}
                        expandedEventId={expandedEventId}
                        toggleExpand={toggleExpand}
                        editMode={editMode}
                        toggleEditMode={toggleEditMode}
                        formData={formData}
                        handleInputChange={handleInputChange}
                        handleStatusChange={handleStatusChange}
                        handleSave={handleSave}
                        showParticipantInfo={showParticipantInfo}
                        handleAttendanceChange={handleAttendanceChange}
                      />
                    ))}
                  </div>
                )}
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListClasses;
