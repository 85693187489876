import { toast } from 'sonner';

export async function creaCompany(companyData) {
    const url = `${process.env.REACT_APP_API_URL}/admin/equipo/empresa/crear`;
    const token = sessionStorage.getItem('token');

    try {
        if (!companyData || !token) {
            toast.error("Datos de compañía o sesión inválidos");
            throw new Error('Missing company data or authentication token');
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(companyData)
        });

        const contentType = response.headers.get('content-type');
        const responseText = await response.text();

        if (!response.ok) {
            console.error('Server responded with:', responseText);
            
            let errorMessage = "Error al crear compañía";
            let parsedError = null;
            
            // Try to parse the error response
            if (contentType && contentType.includes('application/json') && responseText) {
                try {
                    parsedError = JSON.parse(responseText);
                    if (parsedError.message) {
                        errorMessage = parsedError.message;
                    }
                } catch (e) {
                    // Keep default error message if parsing fails
                }
            }
            
            // Set specific error messages based on status code
            switch (response.status) {
                case 400:
                    toast.error("Datos inválidos. Comprueba la información de la compañía.");
                    break;
                case 401:
                    toast.error("No autorizado. Inicia sesión nuevamente.");
                    sessionStorage.removeItem('token');
                    break;
                case 403:
                    toast.error("No tienes permisos para crear compañías.");
                    break;
                case 409:
                    toast.error("Ya existe una compañía con esta información.");
                    break;
                case 500:
                    toast.error("Error del servidor. Intenta más tarde.");
                    break;
                default:
                    toast.error(errorMessage || "Error al crear compañía");
            }
            
            throw new Error(`Failed to create company: ${response.status} ${response.statusText} - ${errorMessage}`);
        }

        let parsedResponse;
        if (contentType && contentType.includes('application/json') && responseText) {
            try {
                parsedResponse = JSON.parse(responseText);
            } catch (e) {
                console.log("Response is not valid JSON, returning as text");
                parsedResponse = { message: responseText || "Compañía creada con éxito" };
            }
        } else {
            parsedResponse = { message: responseText || "Compañía creada con éxito" };
        }
        
        toast.success("Compañía creada con éxito");
        return parsedResponse;
    } catch (error) {
        if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
            toast.error("Error de conexión. Verifica tu conexión a internet.");
            console.error('Network error creating company:', error);
        } else if (!error.message.includes('Failed to create company')) {
            toast.error("Error al crear compañía. Por favor, inténtalo nuevamente.");
            console.error('Error creating company:', error);
        } else {
            console.error('Error details:', error);
        }
        throw error;
    }
}

export async function createTeam(teamData) {
    const url = `${process.env.REACT_APP_API_URL}/admin/equipo/crear`;
    const token = sessionStorage.getItem('token');

    try {
        if (!teamData || !token) {
            toast.error("Datos del equipo o sesión inválidos");
            throw new Error('Missing team data or authentication token');
        }

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(teamData)
        });

        const contentType = response.headers.get('content-type');
        const responseText = await response.text();

        if (!response.ok) {
            console.error('Server responded with:', responseText);
            
            let errorMessage = "Error al crear equipo";
            let parsedError = null;
            
            // Try to parse the error response
            if (contentType && contentType.includes('application/json') && responseText) {
                try {
                    parsedError = JSON.parse(responseText);
                    if (parsedError.message) {
                        errorMessage = parsedError.message;
                    }
                } catch (e) {
                    // Keep default error message if parsing fails
                }
            }
            
            // Set specific error messages based on status code
            switch (response.status) {
                case 400:
                    toast.error("Datos inválidos. Comprueba la información del equipo.");
                    break;
                case 401:
                    toast.error("No autorizado. Inicia sesión nuevamente.");
                    sessionStorage.removeItem('token');
                    break;
                case 403:
                    toast.error("No tienes permisos para crear equipos.");
                    break;
                case 409:
                    toast.error("Ya existe un equipo con esta información.");
                    break;
                case 500:
                    toast.error("Error del servidor. Intenta más tarde.");
                    break;
                default:
                    toast.error(errorMessage || "Error al crear equipo");
            }
            
            throw new Error(`Failed to create team: ${response.status} ${response.statusText} - ${errorMessage}`);
        }

        let parsedResponse;
        if (contentType && contentType.includes('application/json') && responseText) {
            try {
                parsedResponse = JSON.parse(responseText);
            } catch (e) {
                console.log("Response is not valid JSON, returning as text");
                parsedResponse = { message: responseText || "Equipo creado con éxito" };
            }
        } else {
            parsedResponse = { message: responseText || "Equipo creado con éxito" };
        }
        
        toast.success("Equipo creado con éxito");
        return parsedResponse;
    } catch (error) {
        if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
            toast.error("Error de conexión. Verifica tu conexión a internet.");
            console.error('Network error creating team:', error);
        } else if (!error.message.includes('Failed to create team')) {
            toast.error("Error al crear equipo. Por favor, inténtalo nuevamente.");
            console.error('Error creating team:', error);
        } else {
            console.error('Error details:', error);
        }
        throw error;
    }
}

export async function editTeam(id, team) {
    const url = `${process.env.REACT_APP_API_URL}/admin/equipo/actualizar/${id}`;
    const token = sessionStorage.getItem('token');

    try {
        if (!id || !team || !token) {
            toast.error("Datos del equipo o sesión inválidos");
            throw new Error('Missing team ID, team data, or authentication token');
        }

        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(team)
        });

        const contentType = response.headers.get('content-type');
        const responseText = await response.text();

        if (!response.ok) {
            console.error('Server responded with:', responseText);
            
            let errorMessage = "Error al editar equipo";
            let parsedError = null;
            
            // Try to parse the error response
            if (contentType && contentType.includes('application/json') && responseText) {
                try {
                    parsedError = JSON.parse(responseText);
                    if (parsedError.message) {
                        errorMessage = parsedError.message;
                    }
                } catch (e) {
                    // Keep default error message if parsing fails
                }
            }
            
            // Set specific error messages based on status code
            switch (response.status) {
                case 400:
                    toast.error("Datos inválidos. Comprueba la información del equipo.");
                    break;
                case 401:
                    toast.error("No autorizado. Inicia sesión nuevamente.");
                    sessionStorage.removeItem('token');
                    break;
                case 403:
                    toast.error("No tienes permisos para editar este equipo.");
                    break;
                case 404:
                    toast.error("Equipo no encontrado. Verifica el identificador.");
                    break;
                case 500:
                    toast.error("Error del servidor. Intenta más tarde.");
                    break;
                default:
                    toast.error(errorMessage || "Error al editar equipo");
            }
            
            throw new Error(`Failed to edit team: ${response.status} ${response.statusText} - ${errorMessage}`);
        }

        let parsedResponse;
        if (contentType && contentType.includes('application/json') && responseText) {
            try {
                parsedResponse = JSON.parse(responseText);
            } catch (e) {
                console.log("Response is not valid JSON, returning as text");
                parsedResponse = { message: responseText || "Equipo editado con éxito" };
            }
        } else {
            parsedResponse = { message: responseText || "Equipo editado con éxito" };
        }
        
        toast.success("Equipo editado con éxito");
        return parsedResponse;
    } catch (error) {
        if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
            toast.error("Error de conexión. Verifica tu conexión a internet.");
            console.error('Network error editing team:', error);
        } else if (!error.message.includes('Failed to edit team')) {
            toast.error("Error al editar equipo. Por favor, inténtalo nuevamente.");
            console.error('Error editing team:', error);
        } else {
            console.error('Error details:', error);
        }
        throw error;
    }
}



