import { toast } from 'sonner';

export async function updateClass(dto,classId) {
    const url = `${process.env.REACT_APP_API_URL}/profesor/clase/actualizar/${classId}`;
    const token = sessionStorage.getItem('token');

    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dto)
        });

            // Procesar la respuesta POST
            const contentType = response.headers.get('content-type');
            const responseText = await response.text();

            if (!response.ok) {  
                console.error('Failed to create class. Server responded with:', responseText);
                throw new Error('Failed to create class');
            }

            if (contentType && contentType.includes('application/json')) {
                return JSON.parse(responseText);
            } else {
                toast.success("Clase actualizada con éxito");
                return { message: responseText };
            }
    } catch (error) {
        console.error('Error updating student status:', error);
        throw error;
    }
}
