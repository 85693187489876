import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from '../../ui/dialog';
import { getTeamByID } from '../../../provider/profesor/ListClasess/TeamByID';
import { getIndividualByID } from '../../../provider/profesor/ListClasess/individualByID';
import Loader from '../../Loader/Loader';

const ParticipantInfoDialog = ({ isOpen, onClose, participantId, participantType }) => {
  const [participantInfo, setParticipantInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchParticipantInfo() {
      if (!isOpen || !participantId) return;
      
      setLoading(true);
      setError(null);
      
      try {
        let data;
        if (participantType === 'Team') {
          data = await getTeamByID(participantId);
        } else {
          data = await getIndividualByID(participantId);
        }
        setParticipantInfo(data);
      } catch (err) {
        console.error("Error fetching participant info:", err);
        setError("No se pudo obtener la información del participante");
      } finally {
        setLoading(false);
      }
    }
    
    fetchParticipantInfo();
  }, [isOpen, participantId, participantType]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Información del Participante</DialogTitle>
          <DialogDescription>
            Detalles sobre {participantType === 'Team' ? 'el equipo' : 'el estudiante'}
          </DialogDescription>
        </DialogHeader>
        
        {loading && <Loader />}
        
        {error && (
          <div className="p-4 bg-red-50 text-red-800 rounded-md">
            {error}
          </div>
        )}
        
        {!loading && !error && participantInfo && (
          <div className="mt-4">
            {participantType === 'Team' ? (
              /* Team Information */
              <div className="space-y-3">
                <div>
                  <h3 className="text-sm font-semibold">Nombre del equipo</h3>
                  <p>{participantInfo.name}</p>
                </div>
                <div>
                  <h3 className="text-sm font-semibold">Empresa</h3>
                  <p>{participantInfo.companyID?.name}</p>
                </div>
                <div>
                  <h3 className="text-sm font-semibold">NIT de la empresa</h3>
                  <p>{participantInfo.companyID?.nit}</p>
                </div>
                <div>
                  <h3 className="text-sm font-semibold">Horas planeadas</h3>
                  <p>{participantInfo.hoursPlanned} horas</p>
                </div>
                <div>
                  <h3 className="text-sm font-semibold">Estado</h3>
                  <p>{participantInfo.status ? 'Activo' : 'Inactivo'}</p>
                </div>
                {participantInfo.teacherDescription && (
                  <div>
                    <h3 className="text-sm font-semibold">Profesor asignado</h3>
                    <p>{participantInfo.teacherDescription.fullName}</p>
                  </div>
                )}
              </div>
            ) : (
              /* Individual Information */
              <div className="space-y-3">
                <div>
                  <h3 className="text-sm font-semibold">Nombre completo</h3>
                  <p>{participantInfo.fullName}</p>
                </div>
                <div>
                  <h3 className="text-sm font-semibold">Email</h3>
                  <p>{participantInfo.email}</p>
                </div>
                <div>
                  <h3 className="text-sm font-semibold">Teléfono</h3>
                  <p>{participantInfo.phoneNumber}</p>
                </div>
                <div>
                  <h3 className="text-sm font-semibold">Oficina</h3>
                  <p>{participantInfo.office}</p>
                </div>
                <div>
                  <h3 className="text-sm font-semibold">Horas planeadas</h3>
                  <p>{participantInfo.hoursPlanned} horas</p>
                </div>
                <div>
                  <h3 className="text-sm font-semibold">Horas restantes</h3>
                  <p>{participantInfo.hoursRemaining} horas</p>
                </div>
                <div>
                  <h3 className="text-sm font-semibold">Estado</h3>
                  <p>{participantInfo.status ? 'Activo' : 'Inactivo'}</p>
                </div>
                {participantInfo.teacherDescription && (
                  <div>
                    <h3 className="text-sm font-semibold">Profesor asignado</h3>
                    <p>{participantInfo.teacherDescription.fullName}</p>
                  </div>
                )}
                {participantInfo.latestPurchasedHour && (
                  <div>
                    <h3 className="text-sm font-semibold">Última compra de horas</h3>
                    <p>
                      {participantInfo.latestPurchasedHour.hours} horas - 
                      {new Date(participantInfo.latestPurchasedHour.date).toLocaleDateString()}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ParticipantInfoDialog;