import { toast } from "sonner";

export async function getBankDataTeacherbyID(id) {
    const url = `${process.env.REACT_APP_API_URL}/admin/profesorbanco/profesor/${id}`;
    const token = sessionStorage.getItem('token');

    try {
        // Validate inputs
        if (!id) {
            console.error('Missing teacher ID');
            return null; // Return null instead of error for empty ID (might be initial state)
        }
        
        if (!token) {
            toast.error("Sesión inválida. Inicia sesión nuevamente");
            throw new Error("Missing authentication token");
        }
        
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        
        // For debugging
        console.log("Bank data fetch status:", response.status);
        
        // Handle different response status codes
        if (!response.ok) {
            const contentType = response.headers.get('content-type');
            const responseText = await response.text();
            
            // Special case - 404 means no bank data yet (expected for new teachers)
            if (response.status === 404) {
                console.log('No bank data found for this teacher');
                return null;
            }
            
            // For other errors, try to extract meaningful error information
            let errorMessage = "Error al obtener datos bancarios";
            let parsedError = null;
            
            if (contentType && contentType.includes('application/json') && responseText) {
                try {
                    parsedError = JSON.parse(responseText);
                    if (parsedError.message) {
                        errorMessage = parsedError.message;
                    }
                } catch (e) {
                    // Keep default error message if parsing fails
                }
            }
            
            // Handle specific error status codes
            switch (response.status) {
                case 400:
                    toast.error("Solicitud inválida al obtener datos bancarios");
                    break;
                case 401:
                    toast.error("No autorizado. Inicia sesión nuevamente");
                    sessionStorage.removeItem('token');
                    break;
                case 403:
                    toast.error("No tienes permisos para acceder a los datos bancarios");
                    break;
                case 500:
                    toast.error("Error del servidor al obtener datos bancarios. Intenta más tarde");
                    break;
                default:
                    toast.error(errorMessage);
            }
            
            console.error('Server responded with:', responseText);
            throw new Error(`Failed to get bank data: ${response.status} - ${errorMessage}`);
        }
        
        // Process successful response
        const data = await response.json();
        return data;
        
    } catch (error) {
        // Only show generic errors if they weren't already handled
        if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
            toast.error("Error de conexión. Verifica tu conexión a internet");
            console.error('Network error fetching bank data:', error);
        } else if (!error.message.includes('Failed to get bank data')) {
            toast.error("Error al obtener los datos bancarios del profesor");
            console.error('Error fetching bank data:', error);
        } else {
            console.error('Error details:', error);
        }
        
        throw error;
    }
}

