import React, { useState, useEffect } from "react";
import { Card, CardContent } from "../../ui/card";
import { Badge } from "../../ui/badge";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Textarea } from "../../ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import ListofStudents from "../../Administrativo/Grupos/Dashboard/classes/ListofStudents";
import { studentTeamByTeamId } from "../../../provider/profesor/ListClasess/studentTeamByID";
import Loader from "../../Loader/Loader";
import { toast } from "sonner";

const ClassItem = ({
  cls,
  expandedEventId,
  toggleExpand,
  editMode,
  toggleEditMode,
  formData,
  handleInputChange,
  handleStatusChange,
  handleSave,
  showParticipantInfo,
  handleAttendanceChange,
}) => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [attendance, setAttendance] = useState({});

  // Only fetch student list when in edit mode and class is expanded
  useEffect(() => {
    if (
      expandedEventId === cls.id &&
      cls.classScope === "Team" &&
      editMode[cls.id]
    ) {
      setLoading(true); // Set loading state before fetch

      const fetchStudents = async () => {
        try {
          const studentsData = await studentTeamByTeamId(cls.participantID);
          setStudents(studentsData);

          // Always initialize with fresh attendance data (all false)
          const initialAttendance = {};
          studentsData.forEach((student) => {
            initialAttendance[student.id] = false;
          });

          setAttendance(initialAttendance);

          // Update parent component with fresh attendance data
          if (handleAttendanceChange) {
            handleAttendanceChange(cls.id, initialAttendance);
          }
        } catch (error) {
          console.error("Error fetching students:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchStudents();
    }
  }, [
    expandedEventId,
    cls.id,
    cls.classScope,
    cls.participantID,
    // Include only the specific property from editMode that we care about
    editMode[cls.id],
    cls.classStatus,
    handleAttendanceChange,
  ]);

  // Update parent component when attendance changes
  const handleLocalAttendanceChange = (newAttendance) => {
    setAttendance(newAttendance);
    if (handleAttendanceChange) {
      handleAttendanceChange(cls.id, newAttendance);
    }
  };

  // Helper function to calculate remaining hours
  const calculateRemainingHours = (id) => {
    const plannedDuration = parseFloat(cls.plannedDuration || 0);
    const actualDuration = parseFloat(formData[id]?.duration || 0);
    const cancelledDuration = parseFloat(formData[id]?.durationCancelled || 0);

    return plannedDuration - actualDuration - cancelledDuration;
  };

  return (
    <Card key={cls.id} className="border">
      <CardContent className="p-4">
        {loading && <Loader />}
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => toggleExpand(cls.id)}
        >
          <div className="space-y-1">
            <div className="font-medium">
              id{cls.id} - {cls.participantName}
            </div>
            <div className="text-sm text-gray-500">
              {new Date(cls.dateTime).toLocaleTimeString()} - {cls.classType} -{" "}
              {cls.classScope}
            </div>
          </div>

          <div className="flex items-center gap-2">
            <Badge
              className={`flex items-center justify-center p-1 rounded-lg text-white font-semibold ${
                cls.classStatus === "Held"
                  ? "bg-green-500"
                  : cls.classStatus === "To be held"
                  ? "bg-gray-500"
                  : cls.duration > 0
                  ? "bg-orange-500"
                  : "bg-red-500"
              }`}
            >
              <span className="w-2 h-2 rounded-full mr-3 bg-white"></span>
              <span>
                {cls.classStatus === "To be held"
                  ? "Upcoming"
                  : cls.classStatus === "Held"
                  ? "Completed"
                  : cls.duration > 0
                  ? "Partial"
                  : "Canceled"}
              </span>
            </Badge>
            {expandedEventId === cls.id ? (
              <ChevronUp className="h-5 w-5" />
            ) : (
              <ChevronDown className="h-5 w-5" />
            )}
          </div>
        </div>

        {expandedEventId === cls.id && (
          <div className="mt-4">
            {!editMode[cls.id] ? (
              <div>
                <div className="grid grid-cols-2 gap-4 text-sm">
                  <div>
                    <div className="text-gray-500">Full Date</div>
                    <div>{new Date(cls.dateTime).toLocaleDateString()}</div>
                  </div>
                  <div>
                    <div className="text-gray-500">Status</div>
                    <div>
                      {cls.classStatus === "Cancelled" && cls.duration > 0
                        ? "Partial"
                        : cls.classStatus}
                    </div>
                  </div>
                  <div>
                    <div className="text-gray-500">Class Type</div>
                    <div>{cls.classType}</div>
                  </div>
                  <div>
                    <div className="text-gray-500">Participant</div>
                    <div>{cls.classScope}</div>
                  </div>
                  <div>
                    <div className="text-gray-500">Planned Duration</div>
                    <div>{cls.plannedDuration} hours</div>
                  </div>
                  <div>
                    <div className="text-gray-500">Actual Duration</div>
                    <div>{cls.duration} hours</div>
                  </div>
                  {cls.classCancellation && (
                    <>
                      <div>
                        <div className="text-gray-500">Cancelled Duration</div>
                        <div>
                          {cls.classCancellation.durationCancelled} hours
                        </div>
                      </div>
                      <div>
                        <div className="text-gray-500">Cancellation Reason</div>
                        <div>{cls.classCancellation.cancellationReason}</div>
                      </div>
                      <div>
                        <div className="text-gray-500">Canceled By</div>
                        <div>{cls.classCancellation.canceledBy}</div>
                      </div>
                      <div>
                        <div className="text-gray-500">Cancellation Timing</div>
                        <div>{cls.classCancellation.cancellationTiming}</div>
                      </div>
                    </>
                  )}
                  <div>
                    <div className="text-gray-500">Topic</div>
                    <div>{cls.topic || "Not specified"}</div>
                  </div>
                  <div>
                    <div className="text-gray-500">Comment</div>
                    <div>{cls.comment || "Not specified"}</div>
                  </div>
                </div>
                <div className="mt-4 flex flex-col sm:flex-row justify-end gap-2">
                  <Button
                    onClick={() => toggleEditMode(cls.id)}
                    disabled={
                      cls.classStatus === "Held" ||
                      cls.classStatus === "Partial" ||
                      cls.classStatus === "Cancelled"
                    }
                    className="w-full sm:w-auto"
                  >
                    Edit Information
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() =>
                      showParticipantInfo(cls.participantID, cls.classScope)
                    }
                    className="w-full sm:w-auto"
                  >
                    More Information
                  </Button>
                </div>
              </div>
            ) : (
              <div className="space-y-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Class Status
                    </label>
                    <Select
                      value={formData[cls.id]?.classStatus || cls.classStatus}
                      onValueChange={(value) =>
                        handleStatusChange(cls.id, value)
                      }
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select status" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Held">Completed</SelectItem>
                        <SelectItem value="Cancelled">Canceled</SelectItem>
                        <SelectItem value="Partial">Partial</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>

                  {(formData[cls.id]?.classStatus === "Held" ||
                    formData[cls.id]?.classStatus === "Partial") && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Actual Duration (hours)
                      </label>
                      <Select
                        name="duration"
                        value={
                          formData[cls.id]?.duration?.toString() ||
                          cls.duration?.toString()
                        }
                        onValueChange={(value) =>
                          handleInputChange(cls.id, "duration", value)
                        }
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Actual Duration" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="0">0 hours</SelectItem>
                          <SelectItem value="1">1 hour</SelectItem>
                          <SelectItem value="1.5">1:30 hours</SelectItem>
                          <SelectItem value="2">2 hours</SelectItem>
                          <SelectItem value="2.5">2:30 hours</SelectItem>
                          <SelectItem value="3">3 hours</SelectItem>
                          <SelectItem value="3.5">3:30 hours</SelectItem>
                          <SelectItem value="4">4 hours</SelectItem>
                        </SelectContent>
                      </Select>
                      <p className="text-xs text-gray-500 mt-1">
                        Planned duration: {cls.plannedDuration} hours
                      </p>
                    </div>
                  )}

                  {/* Force duration to 0 for cancelled classes */}
                  {formData[cls.id]?.classStatus === "Cancelled" && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Actual Duration
                      </label>
                      <div className="p-2 border rounded-md bg-gray-100 text-gray-500">
                        0 hours (Fixed for cancelled classes)
                      </div>
                      <p className="text-xs text-gray-500 mt-1">
                        Planned duration: {cls.plannedDuration} hours
                      </p>
                    </div>
                  )}
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Topic
                  </label>
                  <Input
                    value={formData[cls.id]?.topic || cls.topic || ""}
                    onChange={(e) =>
                      handleInputChange(cls.id, "topic", e.target.value)
                    }
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Comment
                  </label>
                  <Textarea
                    value={formData[cls.id]?.comment || cls.comment || ""}
                    onChange={(e) =>
                      handleInputChange(cls.id, "comment", e.target.value)
                    }
                    rows={3}
                  />
                </div>

                {/* Only show student attendance for team classes when duration > 0 */}
                {(formData[cls.id]?.classStatus === "Held" ||
                  formData[cls.id]?.classStatus === "Partial") &&
                  cls.classScope === "Team" &&
                  parseFloat(formData[cls.id]?.duration || "0") > 0 &&
                  students.length > 0 && (
                    <div className="border-t pt-4">
                      <h3 className="font-medium mb-3">Student Attendance</h3>
                      <ListofStudents
                        students={students}
                        attendance={attendance}
                        setAttendance={handleLocalAttendanceChange}
                      />
                    </div>
                  )}

                {(formData[cls.id]?.classStatus === "Cancelled" ||
                  formData[cls.id]?.classStatus === "Partial") && (
                  <div className="space-y-4 border-t pt-4">
                    <h3 className="font-medium">Cancellation Information</h3>

                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Cancelled Hours
                        </label>
                        <Select
                          value={(formData[
                            cls.id
                          ]?.durationCancelled).toString()}
                          onValueChange={(value) =>
                            handleInputChange(
                              cls.id,
                              "durationCancelled",
                              value
                            )
                          }
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Cancelled Duration" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="0">0 hours</SelectItem>
                            <SelectItem value="1">1 hour</SelectItem>
                            <SelectItem value="1.5">1:30 hours</SelectItem>
                            <SelectItem value="2">2 hours</SelectItem>
                            <SelectItem value="2.5">2:30 hours</SelectItem>
                            <SelectItem value="3">3 hours</SelectItem>
                            <SelectItem value="3.5">3:30 hours</SelectItem>
                            <SelectItem value="4">4 hours</SelectItem>
                          </SelectContent>
                        </Select>
                        {formData[cls.id]?.classStatus === "Partial" && (
                          <div className="mt-1 text-xs">
                            {calculateRemainingHours(cls.id) === 0 ? (
                              <span className="text-green-600">
                                Hours balance matches planned duration!
                              </span>
                            ) : calculateRemainingHours(cls.id) > 0 ? (
                              <span className="text-red-600">
                                {calculateRemainingHours(cls.id)} hours still
                                unaccounted for
                              </span>
                            ) : (
                              <span className="text-red-600">
                                Total hours exceed planned duration by{" "}
                                {Math.abs(calculateRemainingHours(cls.id))}{" "}
                                hours
                              </span>
                            )}
                          </div>
                        )}
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Cancelled By
                        </label>
                        <Select
                          value={
                            formData[cls.id]?.canceledBy ||
                            cls.classCancellation?.canceledBy ||
                            "Teacher"
                          }
                          onValueChange={(value) =>
                            handleInputChange(cls.id, "canceledBy", value)
                          }
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="Teacher">Teacher</SelectItem>
                            <SelectItem value="Participant">
                              Participant
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Cancellation Timing
                        </label>
                        <Select
                          value={
                            formData[cls.id]?.cancellationTiming ||
                            cls.classCancellation?.cancellationTiming ||
                            "On time"
                          }
                          onValueChange={(value) =>
                            handleInputChange(
                              cls.id,
                              "cancellationTiming",
                              value
                            )
                          }
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Select" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="On time">On time</SelectItem>
                            <SelectItem value="Late">Late</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>

                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          Cancellation Reason
                        </label>
                        <Input
                          value={
                            formData[cls.id]?.cancellationReason ||
                            cls.classCancellation?.cancellationReason ||
                            ""
                          }
                          onChange={(e) =>
                            handleInputChange(
                              cls.id,
                              "cancellationReason",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex justify-end space-x-2">
                  <Button
                    variant="outline"
                    onClick={() => toggleEditMode(cls.id)}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      // Validation before save
                      const currentForm = formData[cls.id] || {};
                      const status = currentForm.classStatus || cls.classStatus;
                      const actualDuration = parseFloat(
                        currentForm.duration || cls.duration || 0
                      );
                      const cancelledDuration = parseFloat(
                        currentForm.durationCancelled || 0
                      );
                      const plannedDuration = parseFloat(
                        cls.plannedDuration || 0
                      );
                      if (!status || status === "To be held") {
                        toast.error("Please select a status");
                        return;
                      }

                      // Force duration to 0 for cancelled classes
                      if (status === "Cancelled") {
                        handleInputChange(cls.id, "duration", "0");
                      }

                      if (
                        status === "Cancelled" &&
                        cancelledDuration !== plannedDuration
                      ) {
                        toast.error(
                          "For cancelled classes, cancelled hours must equal planned hours"
                        );
                        return;
                      }

                      if (
                        status === "Partial" &&
                        actualDuration + cancelledDuration !== plannedDuration
                      ) {
                        toast.error(
                          "For partial classes, actual duration + cancelled hours must equal planned hours"
                        );
                        return;
                      }

                      if (
                        status === "Held" &&
                        actualDuration !== plannedDuration
                      ) {
                        toast.error(
                          "For completed classes, actual duration must equal planned duration"
                        );
                        return;
                      }

                      if (
                        (status === "Cancelled" || status === "Partial") &&
                        (!currentForm.cancellationReason ||
                          !currentForm.cancellationTiming ||
                          !currentForm.canceledBy ||
                          !currentForm.durationCancelled )
                      ) {
                        toast.error("Please complete all cancellation fields");
                        return;
                      }

                      handleSave(cls.id);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ClassItem;
