import React, { useEffect, useState } from "react";
import NavMobile from "../../Nav/NavMobile";
import NavWeb from "../../Nav/NavWeb";
import { Button } from "../../../ui/button";
import "./EstudianteDetalle.css";
import ModifircarEstudiante from "../CrearEstudiante";
import Chart from "./Chart";
import PieChart from "./Chart2";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../../ui/alert-dialog";
import { BellIcon } from "@radix-ui/react-icons";
import { DownloadIcon } from "@radix-ui/react-icons";
import Calendar from "./Calendar";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { Dialog, DialogContent, DialogTrigger } from "../../../ui/dialog";
import { TrashIcon } from "@radix-ui/react-icons";
import { Pencil1Icon } from "@radix-ui/react-icons";
import ModificarClases from "./classes/ModificarClases";
import { getClassesByParticipantIDAndDateTimeBetween } from "../../../../provider/adm/Clases/getClassesByParticipantIDAndDateTimeBetween";
import { deleteClass } from "../../../../provider/adm/Clases/deleteClass";
import { dashboardParticipant } from "../../../../provider/adm/dashboard/dashboardParticipant";
import Loader from "../../../Loader/Loader";
import { Toaster } from "sonner";
const StudentDetail = () => {
  const [studentData, setStudentData] = useState(null);
  const [classes, setClasses] = useState([]);
  const [date, setDate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [studentInfoClasses, setStudentInfoClasses] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // Función para recargar datos
  const refreshData = async () => {
    if (window.refreshCalendarData) {
      await window.refreshCalendarData();
    } else {
      // Fallback: incrementa el trigger para forzar la recarga
      setRefreshTrigger((prev) => prev + 1);
    }
  };
  useEffect(() => {
    const data = localStorage.getItem("selected_student");
    if (data) {
      setStudentData(JSON.parse(data));
    }

    // Recuperar fecha guardada o usar fecha actual
    const storedDate = localStorage.getItem("student_dashboard_date");
    if (storedDate) {
      setDate(JSON.parse(storedDate));
    } else {
      setDate({
        month: parseInt(new Date().getMonth().toString()) + 1,
        year: new Date().getFullYear().toString(),
      });
    }
  }, []);

  // Añadir este efecto para guardar la fecha cuando cambie
  useEffect(() => {
    if (date && date.month && date.year) {
      localStorage.setItem("student_dashboard_date", JSON.stringify(date));
    }
  }, [date]);


  const exportToExcel = async () => {
    try {
      setLoading(true);
  
      // Use the current date state values for the export
      const { year, month } = date;
  
      // Create the API URL with query parameters
      const apiUrl = `${process.env.REACT_APP_API_URL}/admin/estudiante/personalizado/exportar-estudiante?id=${studentData.ID}&year=${year}&month=${month}`;
      const token = sessionStorage.getItem('token'); // Retrieve the JWT token from session storage
  
      // Fetch the Excel file from the API
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`, 
          Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error al exportar: ${response.status}`);
      }
  
      // Get the file as a blob
      const blob = await response.blob();
      
      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);
      
      // Create filename
      const fileName = `Estudiante_${studentData.fullName}_ano_${year}_mes_${month}.xlsx`;
      
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.setAttribute('download', fileName);
      
      // Set additional attributes to try to force the save dialog
      link.setAttribute('type', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      link.setAttribute('target', '_blank');
      
      // Append to the document, trigger click, and then remove
      document.body.appendChild(link);
      link.click();
      
      // Remove the element and revoke the object URL to free up memory
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }, 100);
      
    } catch (error) {
      console.error("Error al exportar el archivo Excel:", error);
      // You could use the Toaster here to show an error message
    } finally {
      setLoading(false);
    }
  };

  if (!studentData) {
    return (
      <div className="flex">
        <Loader />
      </div>
    );
  }

  return (
    <div
      className="min-h-screen flex"
      style={{ overflowY: "hidden", height: "100vh" }}
    >
      {loading && <Loader />}
      <NavMobile />
      <NavWeb />
      <div className="dashboardstudiantesadmvista">
        <div className="dashboardcontainerstudentviewadmin">
          <div className="tituloynotificaciones">
            <h2 className="text-xl font-bold text-gray-900">
              Informacion Estudiante {studentData.fullName} con id=
              {studentData.ID}
            </h2>
            <BellIcon className="h-6 w-6" />
          </div>
          <div className="filtrosandbackbtn">
            <a href="/admin/tablaestudiantes/estudiantesprivados">
              <Button>Back</Button>
            </a>
            <div className="actions">
              <Calendar
                setDate={(date) => setDate(date)}
                date={date}
                ID={studentData.ID}
                setClasses={setClasses}
                getClasses={getClassesByParticipantIDAndDateTimeBetween}
                getInforDashboard={dashboardParticipant}
                setInforDashboard={setStudentInfoClasses}
                setLoading={setLoading}
                refreshTrigger={refreshTrigger}
              />

              <Button
                onClick={() => {
                  const currentDate = new Date();
                  const dateObj = {
                    month: (currentDate.getMonth() + 1).toString(),
                    year: currentDate.getFullYear().toString(),
                  };
                  setDate(dateObj);
                  localStorage.removeItem("student_dashboard_date");
                }}
              >
                limpiar filtros
              </Button>
              <Button onClick={exportToExcel}>
                <DownloadIcon />
                Exportar
              </Button>
            </div>
          </div>
          <div className="resumenDeActividadAcademica">
            <div className="actividadCard">
              <h3>Núm de horas canceladas tarde virtual</h3>
              <p className="total">
                {studentInfoClasses.hoursCanceledParticipantLateVirtual}
              </p>
            </div>
            <div className="actividadCard">
              <h3>Núm de horas canceladas tarde presencial </h3>
              <p className="total">
                {studentInfoClasses.hoursCanceledParticipantLateInPerson}
              </p>
            </div>
            <div className="actividadCard">
              <h3>Total de horas planeadas del mes </h3>
              <p className="total">{studentInfoClasses.hoursPlanned}</p>
            </div>
            <div className="actividadCard">
              <h3>Total horas virtuales</h3>
              <p className="total">{studentInfoClasses.hoursHeldVirtual}</p>
            </div>
            <div className="actividadCard">
              <h3>Total horas presenciales</h3>
              <p className="total">{studentInfoClasses.hoursHeldInPerson}</p>
            </div>
            <div className="actividadCard">
              <h3>Total de clases dictadas</h3>
              <p className="total">{studentInfoClasses.hoursHeld}</p>
            </div>
          </div>
          {studentInfoClasses.monthlyClassStats && (
            <>
              <div className="calendario">
                <PieChart data={studentInfoClasses} />
              </div>
              <div className="grafica">
                <Chart data={studentInfoClasses} />
              </div>
            </>
          )}
          <div className="informacionDetalladaEstudiante">
            <ModifircarEstudiante
              data={studentData}
              context={"editar"}
              flag={1}
            />
          </div>
          <div className="ultimasclasesvistas">
            <Dialog>
              <DialogTrigger asChild>
                <Button>Crear Clase</Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-[825px]">
                <ModificarClases
                  data={{}}
                  studentID={studentData.ID}
                  profesorAsignado={studentData.teacherDescription.fullName}
                  teacherID_fromButton={studentData.teacherDescription.id}
                />
              </DialogContent>
            </Dialog>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>ID clase</TableHead>
                  <TableHead>Teacher</TableHead>
                  <TableHead>Fecha</TableHead>
                  <TableHead>Modalidad</TableHead>
                  <TableHead>H. planeadas</TableHead>
                  <TableHead>Duracion</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {classes.map((classData) => (
                  <TableRow key={classData.id}>
                    <TableCell>{classData.id}</TableCell>
                    <TableCell>{classData.teacherName}</TableCell>
                    <TableCell>
                      {new Date(classData.dateTime).toLocaleString("es", {
                        weekday: "long", // e.g., Monday
                        year: "numeric", // e.g., 2024
                        month: "long", // e.g., December
                        day: "numeric", // e.g., 9
                        hour: "2-digit", // e.g., 01
                        minute: "2-digit", // e.g., 30
                        second: "2-digit", // e.g., 45
                        hour12: true, // e.g., AM/PM format
                      })}
                    </TableCell>
                    <TableCell>{classData.classType}</TableCell>
                    <TableCell>{classData.plannedDuration} H</TableCell>
                    <TableCell>{classData.duration} H</TableCell>
                    <TableCell>
                      <div
                        className={`flex items-center justify-center p-1 rounded-lg text-white font-semibold ${
                          classData.classStatus === "Held"
                            ? "bg-green-500"
                            : classData.classStatus === "To be held"
                            ? "bg-gray-500"
                            : classData.duration > 0
                            ? "bg-orange-500"
                            : "bg-red-500"
                        }`}
                      >
                        <span className="w-2 h-2 rounded-full mr-3 bg-white"></span>
                        <span>
                          {classData.classStatus === "To be held"
                            ? "Por llenar"
                            : classData.classStatus === "Held"
                            ? "Completada"
                            : classData.duration > 0
                            ? "Parcial"
                            : "Cancelada"}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>
                      <AlertDialog>
                        <AlertDialogTrigger>
                          <TrashIcon className="w-6 h-6 text-gray-400" />
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>
                              ¿Estás absolutamente seguro?
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                              Esta acción no se puede deshacer. Esto eliminará
                              permanentemente la clase con ID {classData.id} y
                              todos sus datos asociados.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancelar</AlertDialogCancel>
                            <AlertDialogAction
                              onClick={async (e) => {
                                e.stopPropagation();
                                const confirmReturnHours = window.confirm(
                                  "¿Deseas devolver las horas al estudiante?"
                                );
                                setLoading(true);
                                try {
                                  await deleteClass(
                                    classData.id,
                                    confirmReturnHours
                                  );
                                  refreshData();
                                } catch (error) {
                                  console.error(
                                    "Error eliminando al estudiante:",
                                    error
                                  );
                                } finally {
                                  setLoading(false);
                                }
                              }}
                            >
                              Sí, eliminar
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                      <Dialog>
                        <DialogTrigger asChild>
                          <Pencil1Icon className="w-6 h-6 text-gray-400" />
                        </DialogTrigger>
                        <DialogContent className="sm:max-w-[825px]">
                          <ModificarClases
                            data={classData}
                            profesorAsignado={classData.teacherName}
                            teacherID={classData.teacherID}
                          />
                        </DialogContent>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default StudentDetail;
