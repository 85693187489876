
export async function getTeamByID(id) {
    try {
      // Build URL with query parameters
      let url = `${process.env.REACT_APP_API_URL}/profesor/infoparticipante/equipo/${id}`;
  
      
      const token = sessionStorage.getItem("token");
      
      // Make the API request
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      
      // Check if response is OK
      if (!response.ok) {
        throw new Error(`API error: ${response.status} ${response.statusText}`);
      }
      
      // Parse and return the data
      const data = await response.json();
      return data;
      
    } catch (error) {
      console.error("Error fetching classes:", error);
      return [];
    }
  }
  