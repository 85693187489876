import React, { useState, useEffect } from "react";
import { Button } from "../../ui/button";
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import Loader from "../../Loader/Loader";
import { toast } from "sonner";

const CrearEditarTimeValueProfesores = ({data, context}) => {
  const [valorHoraVirtual, setValorHoraVirtual] = useState("");
  const [valorHoraPresencial, setValorHoraPresencial] = useState("");
  const [valorHoraCanceladaVirtual, setValorHoraCanceladaVirtual] = useState("");
  const [valorHoraCanceladaPresencial, setValorHoraCanceladaPresencial] = useState("");
  const [loading, setLoading] = useState(true); // Start with loading true

  // Format number with thousands separator
  const formatNumber = (value) => {
    if (!value) return "";
    // Remove non-digit characters
    const number = value.toString().replace(/\D/g, "");
    // Format with thousands separator
    return new Intl.NumberFormat('es-CO').format(number);
  };
  
  // Parse formatted number back to number
  const parseNumber = (formattedValue) => {
    if (!formattedValue) return 0;
    return parseInt(formattedValue.replace(/\D/g, ""), 10);
  };

  // Handle input change with formatting
  const handleVirtualValueChange = (e) => {
    const formatted = formatNumber(e.target.value);
    setValorHoraVirtual(formatted);
  };

  const handleInPersonValueChange = (e) => {
    const formatted = formatNumber(e.target.value);
    setValorHoraPresencial(formatted);
  };

  const handleVirtualCancelledValueChange = (e) => {
    const formatted = formatNumber(e.target.value);
    setValorHoraCanceladaVirtual(formatted);
  };

  const handleInPersonCancelledValueChange = (e) => {
    const formatted = formatNumber(e.target.value);
    setValorHoraCanceladaPresencial(formatted);
  };

  // Fetch time value data
  useEffect(() => {
    const fetchTimeValues = async () => {
      setLoading(true);
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/profesor/valorhora/ValoresTiempo`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (!response.ok) {
          throw new Error('Failed to fetch time values');
        }
        
        const timeValues = await response.json();
        
        if (timeValues && timeValues.length > 0) {
          const timeValue = timeValues[0]; // Get the first (and likely only) time value
          setValorHoraVirtual(formatNumber(timeValue.virtualHourValue));
          setValorHoraPresencial(formatNumber(timeValue.inpersonHourValue));
          setValorHoraCanceladaVirtual(formatNumber(timeValue.virtualHourCancelledValue || 0));
          setValorHoraCanceladaPresencial(formatNumber(timeValue.inpersonHourCancelledValue || 0));
        }
      } catch (error) {
        console.error("Error fetching time values:", error);
        toast.error("Error al obtener los valores por hora");
      } finally {
        setLoading(false);
      }
    };
    
    fetchTimeValues();
  }, []);

  const handleCrearEditarTimeValue = async () => {
    setLoading(true);

    try {
      const token = sessionStorage.getItem('token');
      const updatedTimeValue = {
        name: "General", // Always "General"
        virtualHourValue: parseNumber(valorHoraVirtual),
        inpersonHourValue: parseNumber(valorHoraPresencial),
        virtualHourCancelledValue: parseNumber(valorHoraCanceladaVirtual),
        inpersonHourCancelledValue: parseNumber(valorHoraCanceladaPresencial)
      };
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/profesor/valorhora/actualizar/1`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(updatedTimeValue)
      });
      
      if (!response.ok) {
        throw new Error('Failed to update time value');
      }
      
      toast.success("Valores por hora actualizados con éxito");
    } catch (error) {
      console.error("Error updating time values:", error);
      toast.error("Error al actualizar los valores por hora");
    } finally {
      setLoading(false);
      window.location.reload();
    }
  };

  return (
    <>
      {loading && <Loader />}
      <DialogHeader>
        <DialogTitle>{context}</DialogTitle>
        <DialogDescription>
          Cambiar el valor de la hora virtual y presencial para todos los profesores
        </DialogDescription>
      </DialogHeader>
      <div className="grid gap-4 py-4">
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="virtualValue" className="text-right">
            Valor hora virtual
          </Label>
          <div className="col-span-3 relative">
            <Input
              id="virtualValue"
              value={valorHoraVirtual}
              onChange={handleVirtualValueChange}
              className="pl-6 col-span-3 flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors"
              placeholder="10,000"
            />
          </div>
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="inPersonValue" className="text-right">
            Valor hora presencial
          </Label>
          <div className="col-span-3 relative">
            <Input
              id="inPersonValue"
              value={valorHoraPresencial}
              onChange={handleInPersonValueChange}
              className="pl-6 col-span-3 flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors"
              placeholder="20,000"
            />
          </div>
        </div>
        
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="virtualCancelledValue" className="text-right">
            Valor hora virtual cancelada
          </Label>
          <div className="col-span-3 relative">
            <Input
              id="virtualCancelledValue"
              value={valorHoraCanceladaVirtual}
              onChange={handleVirtualCancelledValueChange}
              className="pl-6 col-span-3 flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors"
              placeholder="5,000"
            />
          </div>
        </div>
        <div className="grid grid-cols-4 items-center gap-4">
          <Label htmlFor="inPersonCancelledValue" className="text-right">
            Valor hora presencial cancelada
          </Label>
          <div className="col-span-3 relative">
            <Input
              id="inPersonCancelledValue"
              value={valorHoraCanceladaPresencial}
              onChange={handleInPersonCancelledValueChange}
              className="pl-6 col-span-3 flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors"
              placeholder="10,000"
            />
          </div>
        </div>
      </div>
      <DialogFooter>
        <Button type="button" variant="ghost" onClick={handleCrearEditarTimeValue}>
          Guardar cambios
        </Button>
      </DialogFooter>
    </>
  );
};

export default CrearEditarTimeValueProfesores;
