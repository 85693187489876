import { toast } from "sonner";

export async function dashboardTeacher(idTeacher, year, month) {
    try {
        // Validate input parameters
        if (!idTeacher) {
            toast.error("ID de profesor no proporcionado");
            throw new Error("Missing teacher ID");
        }
        
        if (!year || !month) {
            toast.error("Periodo no especificado (año/mes)");
            throw new Error("Missing year or month parameter");
        }
        
        const token = sessionStorage.getItem("token");
        if (!token) {
            toast.error("Sesión inválida. Inicia sesión nuevamente");
            throw new Error("Missing authentication token");
        }
        
        const url = `${process.env.REACT_APP_API_URL}/admin/dashboard/profesor?teacherID=${idTeacher}&year=${year}&month=${month}`; 
        
        const resp = await fetch(url, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        });
        if (!resp.ok) {
            const contentType = resp.headers.get('content-type');
            const responseText = await resp.text();
            
            let errorMessage = "Error al obtener información del dashboard";
            let parsedError = null;
            
            // Try to parse the error response
            if (contentType && contentType.includes('application/json') && responseText) {
                try {
                    parsedError = JSON.parse(responseText);
                    if (parsedError.message) {
                        errorMessage = parsedError.message;
                    }
                } catch (e) {
                    // Keep default error message if parsing fails
                }
            }
            
            // Handle specific status codes
            switch (resp.status) {
                case 400:
                    toast.error("Parámetros inválidos para dashboard del profesor");
                    break;
                case 401:
                    toast.error("No autorizado. Inicia sesión nuevamente");
                    sessionStorage.removeItem('token');
                    break;
                case 404:
                    toast.error("Datos no encontrados. Verifica que el profesor tenga información para el periodo seleccionado");
                    break;
                case 422:
                    toast.error("No se encontraron datos bancarios del profesor. Por favor complete esta información primero");
                    break;
                case 500:
                    toast.error("Error del servidor. Intenta más tarde");
                    break;
                default:
                    toast.error(errorMessage);
            }
            
            throw new Error(`Dashboard fetch failed: ${resp.status} - ${errorMessage}`);
        }
        
        const data = await resp.json();
        
        // Check if data is empty or incomplete
        if (!data || Object.keys(data).length === 0) {
            toast.warning("No hay datos disponibles para el periodo seleccionado");
            return null;
        }
        
        // Format and return the dashboard data
        return {
            hoursCanceledParticipantOnTime: data.hoursCanceledParticipantOnTime || 0,
            hoursCanceledTeacherOnTime: data.hoursCanceledTeacherOnTime || 0,
            classesHeldInPerson: data.classesHeldInPerson || 0,
            classesHeldVirtual: data.classesHeldVirtual || 0,
            hoursHeld: data.hoursHeld || 0,
            hoursHeldVirtual: data.hoursHeldVirtual || 0,
            hoursHeldInPerson: data.hoursHeldInPerson || 0,
            hoursPlanned: data.hoursPlanned || 0,
            totalVirtualValue: data.totalVirtualValue || 0,
            totalInPersonValue: data.totalInPersonValue || 0,
            hoursCanceledParticipantLateVirtual: data.hoursCanceledParticipantLateVirtual || 0,
            hoursCanceledParticipantLateInPerson: data.hoursCanceledParticipantLateInPerson || 0,
            hoursCanceledTeacherLate: data.hoursCanceledTeacherLate || 0,
        };
        
    } catch (error) {
        if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
            toast.error("Error de conexión. Verifica tu conexión a internet");
        } else if (!error.message.includes('Dashboard fetch failed')) {
            // Only show generic error if not already handled above
            toast.error("Error al obtener información del profesor. Inténtalo nuevamente");
        }
        
        console.error("Dashboard teacher fetch error:", error);
        return null;
    }
}